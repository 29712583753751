import {Modal} from 'react-bootstrap'
import {EventCategory, EventCategoryGroup, OeeEventLog, SubEventCategory} from '../../core/_models'
import LoadingOverlayWrapper from '../../components/LoadingOverlayWrapper'
import {KTSVG} from '../../../_metronic/helpers'
import {useState} from 'react'
import {
  createEventLog,
  updateEventLog,
  useEventCategories,
  useEventCategoryGroups,
} from '../../core/requests/oee'
import clsx from 'clsx'
import {motion} from 'framer-motion'
import {useQueryClient} from 'react-query'
import {useProfile} from '../../core/requests/auth'

type CommonProps = {
  show: boolean
  handleClose: () => void
  isEditMode?: boolean
  eventLogToEdit?: OeeEventLog
  categoryOfEventLogToEdit?: EventCategory | SubEventCategory
}

type ProductionLineProps = CommonProps & {productionLineId: string; assetId?: never}
type AssetProps = CommonProps & {assetId: string; productionLineId?: never}
type Props = ProductionLineProps | AssetProps

const LogEventModal = ({
  show,
  handleClose,
  categoryOfEventLogToEdit,
  isEditMode = false,
  eventLogToEdit,
  ...ids
}: Props) => {
  const [selectedCategory, setSelectedCategory] = useState<
    EventCategory | SubEventCategory | undefined
  >(isEditMode ? categoryOfEventLogToEdit : undefined)
  const [note, setNote] = useState<string>(isEditMode ? eventLogToEdit?.note || '' : '')
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [caetgoryChanged, setCategoryChanged] = useState<boolean>(false)
  const [expandedCategoryTrace, setExpandedCategoryTrace] = useState<
    (EventCategory | SubEventCategory)[]
  >([])

  const {data: eventCategoryGroups} = useEventCategoryGroups({
    entities: [ids.productionLineId || ids.assetId!],
  })
  const {data: activeCategoryGroups} = useEventCategoryGroups({
    isActive: true,
    options: {enabled: eventCategoryGroups?.items.length === 0},
  })
  const eventCategoryGroup = eventCategoryGroups?.items.at(0) || activeCategoryGroups?.items.at(0)

  const queryClient = useQueryClient()
  const {data: profile} = useProfile()
  const {data: eventCategories, isLoading: isEventCategoriesLoading} = useEventCategories({
    eventCategoryGroups: eventCategoryGroup ? [eventCategoryGroup._id] : undefined,
    options: {enabled: !!eventCategoryGroup},
  })

  const close = () => {
    setSelectedCategory(undefined)
    setExpandedCategoryTrace([])
    setNote('')
    handleClose()
  }

  const onSubmit = async () => {
    if (!profile?.organization || !selectedCategory) return
    setIsSubmitting(true)
    try {
      const categories = expandedCategoryTrace.map((category) => category._id!)
      categories.push(selectedCategory._id!)
      if (isEditMode && eventLogToEdit) {
        await updateEventLog({
          id: eventLogToEdit._id,
          categories: caetgoryChanged ? categories : eventLogToEdit.categories,
          note: note,
        })
      } else {
        await createEventLog({
          organization: profile.organization._id,
          entity: ids.productionLineId || ids.assetId!,
          categories: categories,
          note: note,
        })
      }

      await queryClient.invalidateQueries('eventLogs')
    } catch (error) {
      console.error(error)
    } finally {
      setIsSubmitting(false)
      close()
    }
  }

  const onExpand = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    category: EventCategory | SubEventCategory
  ) => {
    e.stopPropagation()
    setExpandedCategoryTrace([...expandedCategoryTrace, category])
    setSelectedCategory(undefined)
  }

  const onContract = () => {
    setExpandedCategoryTrace(expandedCategoryTrace.slice(0, -1))
    setSelectedCategory(undefined)
  }

  return (
    <Modal
      show={show}
      tabIndex={-1}
      aria-hidden='true'
      size='lg'
      dialogClassName='modal-dialog modal-dialog-centered'
      onHide={close}
      backdrop={isEventCategoriesLoading ? 'static' : true}
    >
      <LoadingOverlayWrapper
        loading={eventCategoryGroup === undefined || eventCategories === undefined}
      >
        <div className='modal-header pb-0 border-0 justify-content-end'>
          <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={close}>
            <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
          </div>
        </div>
        <div className='modal-body mx-5 mx-xl-18 pt-0'>
          <h1 className='text-center mb-8'>{`Log Event`}</h1>

          <div className='fs-5 fw-semibold mb-2'>
            <span>Choose an Event Category</span>
          </div>
          {expandedCategoryTrace.length > 0 && (
            <div className='d-flex'>
              <i className='fa-solid fa-reply fs-2 cursor-pointer mb-2 me-2' onClick={onContract} />
              <div className='fst-italic fw-normal'>
                {`Showing: ${expandedCategoryTrace.map((category) => category.title).join(' > ')}`}
              </div>
            </div>
          )}

          <div className='row mb-8'>
            {(expandedCategoryTrace.at(-1)?.sub_event_categories || eventCategories?.items)?.map(
              (eventCategory, index) => (
                <div className={`${index !== 0 ? 'mt-3' : ''} col-6`} key={eventCategory._id}>
                  <div
                    className={clsx(
                      'btn btn-outline btn-outline-dashed p-5 d-flex align-items-center h-100',
                      selectedCategory?._id === eventCategory._id && 'active'
                    )}
                    onClick={() => {
                      setCategoryChanged(true)
                      setSelectedCategory(eventCategory)
                    }}
                  >
                    <div
                      className='me-2 shadow'
                      style={{
                        backgroundColor: eventCategory.color,
                        width: '1em',
                        height: '1em',
                        borderRadius: '0.25em',
                      }}
                    />
                    <span className='text-dark fw-bold d-block me-1'>{eventCategory.title}</span>
                    <i className={eventCategory.icon} />
                    {eventCategory.sub_event_categories.length > 0 && (
                      <button
                        className='btn btn-outline-secondary ms-auto p-2'
                        onClick={(e) => onExpand(e, eventCategory)}
                      >
                        <i
                          className='fa-solid fa-arrow-right-long fs-3'
                          style={{color: 'rgb(161, 165, 183)'}}
                        />
                      </button>
                    )}
                  </div>
                </div>
              )
            )}
          </div>

          <label className='fs-6 fw-semibold mb-2'>Note</label>
          <textarea
            className='form-control form-control-solid'
            name='note'
            rows={3}
            placeholder='Add a note'
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />

          <div className='d-flex flex-center pt-5'>
            <button className='btn btn-light me-3' onClick={close}>
              Cancel
            </button>
            <button
              className='btn btn-primary'
              disabled={isSubmitting || !selectedCategory}
              onClick={onSubmit}
            >
              Log Event
            </button>
          </div>
        </div>
      </LoadingOverlayWrapper>
    </Modal>
  )
}

export default LogEventModal
