import {UseQueryOptions} from 'react-query'
import {WidgetDataType, WidgetType} from '../pages/dashboard/dashboard-grid/widgets/_models'
import {Action as AutomationAction} from '../modals/automations/actions/actions'
import {Condition, ConditionType} from '../modals/automations/conditions/conditions'

export type Country = 'DK' | 'DE' | 'FR' | 'FI' | 'US' | 'UK' | 'UA' | 'SE' | 'ES' | 'NO'
export type Region = 'DK1' | 'DK2'
export const manufacturingTypes = ['discrete', 'continuous'] as const
export type ManufacturingProcess = typeof manufacturingTypes[number]
export type Currency =
  | 'DKK'
  | 'EUR'
  | 'USD'
  | 'GBP'
  | 'NZD'
  | 'CHF'
  | 'PLN'
  | 'SEK'
  | 'NOK'
  | 'ISK'
  | 'AUD'
  | 'CAD'
export const partTypes = [
  'Shaft',
  'Coupling',
  'Belt Drive',
  'AC Motor',
  'DC MOTOR',
  'EC Motor',
  'Rolling Element Bearing',
  'Centrifugal Pump',
  'Positive Displacement Pump',
  'Compressor',
  'Gear',
] as const

export const assetStates = [
  'Offline',
  'Calibrating',
  'Good',
  'Satisfactory',
  'Unsatisfactory',
  'Unacceptable',
]
export type AssetState = typeof assetStates[number]

export type PartType = typeof partTypes[number]

export enum sensorTypesMap {
  vibration = 'vibration',
  temperature = 'temperature',
  output = 'output',
  ampere = 'ampere',
  h2s = 'h2s',
  voc = 'voc',
  rpm = 'rpm',
}

export const sensorTypes = Object.keys(sensorTypesMap)
export type SensorType = typeof sensorTypes[number]

export const voltages = ['240v', '400v', '690v'] as const
export type Voltage = typeof voltages[number]

export enum manufacturersMap {
  PRESSAC = 'PRESSAC',
  TREON = 'TREON',
  AMI = 'AMI',
  OTHER = 'OTHER',
}

export const manufacturers = Object.values(manufacturersMap)
export type Manufacturer = typeof manufacturers[number]
export const AMIModels = ['CM', 'CM Connect', 'Axion', 'GEN3'] as const
export type AMIModel = typeof AMIModels[number]
export const TREONModels = ['1KHZ', '6KHZ'] as const
export type TREONModel = typeof TREONModels[number]
export const PRESSACModels = ['CT Clamp', 'Pulse Counter'] as const
export type PRESSACModel = typeof PRESSACModels[number]
export const partModels = [...AMIModels, ...TREONModels, ...PRESSACModels] as const
export type PartModel = typeof partModels[number]
export const axes = ['axial', 'vertical', 'horizontal'] as const
export type Axis = typeof axes[number]
export const batchStatuses = ['pending', 'running', 'done', 'cancelled']
export type BatchStatus = typeof batchStatuses[number]
export const operationalEventCategories = [
  'running',
  'failure',
  'idle',
  'line-restrain',
  'not-scheduled',
  'unscheduled',
  'auto-detected',
]
export type OperationalEventCategory = typeof operationalEventCategories[number]
export const entityGroups = [
  'factory',
  'area',
  'production_line',
  'section',
  'asset',
  'part',
  'placement',
  'sensor',
  'gateway',
  'plc',
] as const
export type EntityGroup = typeof entityGroups[number]
export const entityGroupsPretty = [
  'Factory',
  'Area',
  'Production Line',
  'Section',
  'Asset',
  'Part',
  'Placement',
  'Sensor',
  'Gateway',
  'PLC',
] as const
export type EntityGroupPretty = typeof entityGroupsPretty[number]
export const runningTimes = ['production', 'reworking', 'reduced-speed']
export type RunningTime = typeof runningTimes[number]
export const priorities = ['LOW', 'MEDIUM', 'HIGH', 'URGENT']
export type Priority = typeof priorities[number]
export const workOrderCategories = [
  'Repair',
  'Safety Work',
  'Corrective Maintenance',
  'Support Maintenance',
  'Restoration',
  'Audit',
  'Inspection',
  'Shutdown Repair Work',
  'Setup',
  'Cleaning',
  'Preventive Maintenance',
]
export type WorkOrderCategory = typeof workOrderCategories[number]
export const workOrderStatuses = ['pending', 'in progress', 'done']
export type WorkOrderStatus = typeof workOrderStatuses[number]
export const timeFields = ['start_time', 'end_time', 'planned_start_time']
export type TimeField = typeof timeFields[number]
export const deviceState = ['Online', 'Offline']
export type DeviceState = typeof deviceState[number]
export const healthEstimateFeatures = [
  'SimpleHealth',
  'ISO',
  'IsolationForestFFT',
  'IsolationForestRMSTemp',
  'TrendDetectionFFT',
  'TrendDetectionRMSX',
  'TrendDetectionRMSY',
  'TrendDetectionRMSZ',
  'TrendDetectionTemp',
]
export type HealthEstimateFeature = typeof healthEstimateFeatures[number]
export const NotificationChannels = ['Email', 'SMS', 'Push']
export type NotificationChannel = typeof NotificationChannels[number]
export const subscriptionTopics = [
  'health-changed',
  'sensor-pinged',
  'threshold-crossed',
  'point-anomaly-detected',
  'collective-anomaly-detected',
  'batch-initiated',
  'batch-started',
  'batch-stopped',
  'batch-cancelled',
  'batch-completed',
  'battery-level-low',
  'device-connected',
  'device-connection-lost',
  'co2-emission-limit-exceeded',
  'verification-requested',
  'verification-completed',
  'entity-failed',
  'entity-recovered',
  'upcoming-work-order-alert',
  'entity-started',
  'entity-stopped',
  'standby-started',
  'standby-stopped',
]
export type SubscriptionTopic = typeof subscriptionTopics[number]
export const timespans = ['minute', 'hour', 'day', 'week', 'month', 'year']
export type Timespan = typeof timespans[number]

export type PaginatedResponse<T> = {
  items: T[]
  total: number
  page: number
  size: number
  pages: number
}

export type OrganizationQueryProps = {
  organization?: string
}

export type OrganizationRequestParams = {
  organization?: string
}

export type PaginatedQueryProps<T> = {
  page?: number
  size?: number
  options?: UseQueryOptions<PaginatedResponse<T>, Error>
}

export type PaginatedRequestParams = {
  page?: number
  size?: number
}

export type BaseModel = {
  _id?: string
  is_archived: boolean
  created_at?: string
  updated_at?: string
  created_by?: string
  updated_by?: string
}

export type Zone = {
  _id: string
  name?: string
  country_name?: string
}

export type Factory = {
  _id: string
  organization: Reference
  gallery?: Gallery
  name: string
  zone?: Zone & BaseModel
  location?: GeoCoordinates
  fixed_power_price?: number
  currency?: Currency
}

export type ReferenceUUID = {
  _id: string
}

export type Reference = ReferenceUUID & {
  name: string
}

export type Area = {
  _id: string
  organization: Reference
  external_id?: string
  gallery?: Gallery
  factory: Reference
  name: string
}

export type Image = {
  id: number
  url: string
  thumbnail_url: string
}

export type Gallery = {
  profile_image: Image
  images: Image[]
}

export type ProductionLine = {
  _id: string
  organization: Reference
  external_id?: string
  gallery?: Gallery
  factory: Reference
  area: Reference
  name: string
  manufacturing_type?: ManufacturingProcess
  placements_order: string[]
  auto_stop_detection_tolerance: number
}

export type ProductionLineState = {
  status: 'operating' | 'stopped'
  time_in_state: number
  batch_state?: BatchState
  batch?: Batch
}

export type Section = {
  _id: string
  organization: Reference
  external_id?: string
  gallery?: Gallery
  factory: Reference
  area: Reference
  production_line: Reference
  name: string
}

interface AssetProfile {
  sensitivity_preset: 'LOW' | 'MEDIUM' | 'HIGH'
}

export type Asset = {
  _id: string
  organization: Reference
  external_id?: string
  gallery?: Gallery
  factory: Reference
  area: Reference
  production_line?: Reference
  section?: Reference
  name: string
  force_calibrating: boolean
  zone: 100 | 200 | 300 | 400
  first_state_at?: string
  last_state_at?: string
  last_state_change_at?: string
  state: AssetState
  profile: AssetProfile | null
}

export type Organization = {
  _id: string
  name: string
  code?: string
}

export type Permission = {
  id: number
  title: string
  description: string
}

export type Role = {
  _id: string
  organization: Reference
  factory?: Reference
  name: string
  permissions: Permission[]
}

export type RoleReference = {
  _id: string
  name: string
  permissions: Permission[]
}

export type User = {
  _id: string
  email: string
  phone_number?: string
  organization?: Reference
  role?: Role & BaseModel
}

export type Profile = {
  _id: string
  email: string
  phone_number?: string
  organization?: Reference
  current_role?: RoleReference
  is_staff: boolean
}

export type Part = {
  _id: string
  organization: Reference
  external_id?: string
  gallery?: Gallery
  factory: Reference
  area: Reference
  production_line?: Reference
  section?: Reference
  asset?: Reference
  manufacturer?: string
  model_number?: string
  type?: PartType
  // Motor specific fields
  kilowatt?: number
  line_frequency?: number
  rotor_bars?: number
  stator_coils?: number
  // Pump specific fields
  vanes?: number
  // RollingElementBearing specific fields
  placement?: string
  rolling_elements?: number
  contact_angle?: number
  inner_diameter?: number
  outer_diameter?: number
  damin_d1?: number
  damax_d2?: number
}

export type PartReference = {
  _id: string
  manufacturer?: string
  model_number?: string
}

export type SensorLog = {
  sensor: Pick<Sensor, 'device_id' | 'data_types' | 'track_scrap' | 'x' | 'y' | 'z'> & {
    uuid: string
  }
  attached_at: string
  detached_at: string
}

export type Placement = {
  _id: string
  organization: Reference
  external_id?: string
  gallery?: Gallery
  factory: Reference
  area: Reference
  production_line?: Reference
  section?: Reference
  asset?: Reference
  part?: PartReference
  name: string
  sensor_logs: SensorLog[]
}

export type Sensor = {
  _id: string
  organization: Reference
  external_id?: string
  gallery?: Gallery
  factory: Reference
  area: Reference
  production_line?: Reference
  section?: Reference
  asset?: Reference
  part?: PartReference
  placement: Reference
  plc?: Reference
  device_id: string
  data_types: SensorType[]
  manufacturer: Manufacturer
  model?: PartModel
  offline_tolerance_minutes?: number
  x?: Axis
  y?: Axis
  z?: Axis
  wakeup_threshold?: number
  track_scrap: boolean
  battery_low: boolean
  first_record_at?: string
  latest_record_at?: string
  state: DeviceState
  is_demo: boolean
}

export type Gateway = {
  _id: string
  organization: Reference
  external_id?: string
  gallery?: Gallery
  factory: Reference
  area: Reference
  device_id: string
  is_offline: boolean
  offline_tolerance_minutes: number
  first_record_at?: string
  latest_record_at?: string
  state: DeviceState
}

export type Plc = {
  _id: string
  organization: Reference
  external_id?: string
  gallery?: Gallery
  factory: Reference
  area: Reference
  production_line?: Reference
  section?: Reference
  asset?: Reference
  name: string
}

export type FactoryEntity = {
  type: EntityGroup
  entity: (
    | Factory
    | Area
    | ProductionLine
    | Section
    | Asset
    | Part
    | Placement
    | Sensor
    | Gateway
    | Plc
  ) &
    BaseModel
}

export type ServerError = {
  detail: [
    {
      loc: string[]
      msg: string
      type: string
    }
  ]
}

export type AxiosServerError = {
  detail: string
}

export type BatchTemplate = {
  _id: string
  organization: string
  product: string
  name: string
  auto_stop_detection_tolerance: number
  total_product_quantity: number
}

export type BatchState = {
  batch: string
  timestamp: number
  eta: number
  product_processed: number
  product_rate: number
  cycle_time: number
  scrap: number
  scrap_rate: number
}

export type ICreateBatch = {
  create_from_template: string
  production_line: string
  planned_start_time: string
  theoretical_product_rate: number
  total_product_quantity?: number
}

export type Batch = {
  _id: string
  production_line: string
  product?: string
  name: string
  batch_number?: string
  created_from_template: string
  status: BatchStatus
  planned_start_time: string
  planned_production_time: number
  start_time?: string
  end_time?: string
  theoretical_product_rate: number
  total_product_quantity: number
  auto_stop_detection_tolerance: number
  scrap?: number
  multiplier?: number
}

export type StopCause = {
  _id: string
  organization: Reference
  name: string
  subcauses: string[]
  color?: string
}

export type OeeMetric = {
  availability: number
  performance: number
  quality: number
  oee: number
}

export type Dashboard = {
  _id: string
  user: string
  name: string
  tags: string[]
  shared_with?: string[]
}

export type Layout = {
  i: string
  x: number
  y: number
  w: number
  h: number
  min_w?: number
  max_w?: number
  min_h?: number
  max_h?: number
  moved?: boolean
  static?: boolean
  is_draggable?: boolean
  is_resizable?: boolean
  resize_handles?: ('e' | 'se' | 's' | 'sw' | 'w' | 'nw' | 'n' | 'ne')[]
  is_bounded?: boolean
}

export type Widget = {
  _id: string
  dashboard: string
  name: string
  type: WidgetType
  entity_group: EntityGroup
  entity: string
  entities: string[]
  data_types?: WidgetDataType[]
  operational_event_categories?: OperationalEventCategory[]
  dynamic_time_range?: string
  start_time?: string
  end_time?: string
  time_interval?: string
  layout: Layout
  timespan?: Timespan
  multiplier?: number
  by_product?: boolean
  quantity?: number
  category?: WorkOrderCategory
  status?: WorkOrderStatus
  operational_states?: OperationalState[]
  operational_state_intervals?: [
    [number, number | null], // running
    [number, number | null], // standby
    [number, number | null] // shutdown
  ]
}

export type Product = {
  _id: string
  organization: string
  sku: string
  name: string
  compatible_production_lines: string[]
}

export type BatchTemplateRates = {
  production_line: string
  average_theoretical_product_rate?: number
  optimal_theoretical_product_rate?: number
}

export type CmmsRelation = {
  external_entity: string
  external_id?: string
  cmms_provider: string
  cmms_tenant?: string
}

export type RecurringPeriod = {
  start_time: string
  end_time?: string
  recurring_days?: number[]
  recurring_dates?: number[]
  recurring_months?: number[]
}

export type Action = {
  _id: string
  description: string
  is_completed: boolean
  completed_by?: string
}

export interface Attachment {
  name: string
  url: string
  preview_url: string
  content_type: string
}

export type FileAttachment =
  | {
      file: File
      name?: never
      url?: never
      preview_url?: never
      content_type?: never
    }
  | (Attachment & {file?: never})

export type WorkOrder = {
  _id: string
  // organization: string
  maintenance_plan?: string
  work_order_template?: string
  factory_entity?: string
  external_id?: string
  title: string
  assignees?: string[]
  planned_start_time: string
  start_time?: string
  expected_duration: number
  end_time?: string
  priority?: Priority
  category?: WorkOrderCategory
  sub_category?: string
  status: WorkOrderStatus
  description?: string
  note?: string
  actions: (Action & BaseModel)[]
  attachments?: Attachment[]
}

export type MaintenancePlan = {
  _id: string
  organization: Reference
  name: string
  factory_entities: string[]
  description?: string
}

export type MaintenanceEvent = Omit<WorkOrder, 'actions'>

export type WorkOrderTemplate = {
  _id: string
  organization: Reference
  title: string
  actions?: string[]
  maintenance_plan?: string
  factory_entity?: string
  assignees?: string[]
  expected_duration: number
  recurring_hourly_interval?: number
  recurring_periods?: RecurringPeriod[]
  priority?: Priority
  category?: WorkOrderCategory
  sub_category?: string
  description?: string
  is_recurring: boolean
}

export type Notification = {
  _id: string
  organization: string
  user?: User
  subscription: string
  title: string
  message: string
  topic: string
  channels: NotificationChannel[]
  sent_at: string
  entity?: string
}

export type EventLog = {
  _id: string
  organization: string
  title: string
  message: string
  event_type: string
  occurred_at: string
  entity?: string
}

export type GeoCoordinates = {
  type: 'Point'
  coordinates: [number, number]
}

export type Invitation = {
  _id: string
  role: RoleReference
  user: string
  activated_at?: string
  is_activated: boolean
}

export type DeletionConfirmation = {
  was_deleted: boolean
}

export type PowerFactor = TimedValue

export type TimeInStateStats = {
  time_stats: {
    key: string
    duration: number
    count: number
    short_duration: number
    subcategories: {
      key: string
      duration: number
      count: number
      short_duration: number
    }[]
  }[]
  subscription?: string
}

export type ProductionLineStats = {
  time: string
  product_processed: number
  product_rate: number
  cycle_time: number
  scrap: number
  scrap_rate: number
  subscription: string
}

export type ProductionLineOee = {
  availability: number
  performance: number
  quality: number
  oee: number
  subscription: string
}

export type ProductRate = TimedValue

export type Consumption = {
  power: PowerFactor[]
  energy: number
  carbon_dioxide_emissions: number
}

export type StandbyStats = {
  power: PowerFactor[]
  energy: number
  carbon_dioxide_emissions: number
  potential_savings: {currency: Currency; value: number}
}

export type PlacementFFT = [number, number][]

export type PlacementFFTSearch = {
  time: string
}

export type TimedValue = {
  time: string
  value: number
}

export type PlacementGraphs = {
  rms_x: TimedValue[]
  rms_y: TimedValue[]
  rms_z: TimedValue[]
  temperature: TimedValue[]
  battery_voltage: TimedValue[]
}

export type AssetGraphs = {
  simple_health_history: TimedValue[]
  advanced_health_history: TimedValue[]
  anomaly_history: TimedValue[]
}

export type SensorRecord = TimedValue

export type Subscription = {
  _id: string
  organization: string
  user?: string
  topic: string
  channels: NotificationChannel[]
  is_enabled: boolean
  additional_message?: string
  cooldown_hours?: number
  last_triggered?: string
  last_checked?: string
  entities?: string[]
  zones?: (100 | 200 | 300 | 400)[]
  data_types?: SensorType[]
  axis?: 'X' | 'Y' | 'Z'
  name?: string
  minimum?: number
  maximum?: number
  n_neighbours?: number
}

export type AssetChecklist = {
  part_type: PartType
  potential_faults: string[]
  actions: string[]
}

export type AssetsStatus = {state: AssetState; count: number}

export type OnlineStatus = {state: DeviceState; count: number}

export type Machine = ProductionLine | Asset

export interface MachineOperationalStats {
  [_id: string]: {
    duration: number
    count: number
    short_duration: number
  } & (
    | {
        unscheduled: true
        operational: false
      }
    | {
        unscheduled: false
        operational: true
      }
  )
}

export type EventCategoryGroup = {
  _id: string
  title: string
  template: boolean
  organization?: string
  entities?: string[]
  is_active: boolean
}

export type SubEventCategory = {
  _id?: string
  title: string
  color: string
  icon: string
  sub_event_categories: SubEventCategory[]
}

export type EventCategory = {
  _id: string
  template: boolean
  organization?: string
  event_category_groups: string[]
  unscheduled: boolean
  operational: boolean
  title: string
  color: string
  icon: string
  sub_event_categories: SubEventCategory[]
}

export type OeeEventLog = {
  _id: string
  time: string
  organization: string
  entity: string
  categories: string[]
  logged_by: string
  note?: string
  unix: number
  utc_timestamp: string
  iso_timestamp: string
}

export interface MachineProductionStats {
  product_processed: [number, number][]
  scrap: [number, number][]
  rolling_product_rate: [number, number | null][]
  rolling_scrap_rate: [number, number | null][]
  avg_product_rate: number
  avg_scrap_rate: number
  cycle_time: number
  avg_scrap_ratio: number
}

export const operationalStates = ['running', 'standby', 'shutdown']
export type OperationalState = typeof operationalStates[number]

export type MachineVerificationRequest = {
  _id: string
  organization: ReferenceUUID & {name?: string}
  machine: string
  note?: string
  completed_at?: string
  completed_by?: string
  response?: string
  cancelled_at?: string
  cancelled_by?: string
  is_completed: boolean
  is_cancelled: boolean
}

export type Tag = {
  _id: string
  organization: Reference
  entity?: string
  key: string
  value: string
}

export type Automation = {
  _id: string
  organization: string
  user?: string
  description?: string
  enabled: boolean
  event: string
  conditions: Condition[]
  actions: AutomationAction[]
  cooldown_minutes?: number
  last_triggered?: string
}

export type AutomationEvent = [
  string,
  {title: string; conditions: ConditionType[]; variables: string[]}
]

export type NotificationGroup = {
  _id: string
  organization: string
  name: string
  users: string[]
}
