import {Modal} from 'react-bootstrap'
import LogEventButton from '../../../../components/LogEventButton'
import useModalState from '../../../../hooks/UseModalState'
import {WidgetProps} from './Widget'
import {KTSVG} from '../../../../../_metronic/helpers'
import {createEventLog, useEventCategory} from '../../../../core/requests/oee'
import {EventCategory, SubEventCategory} from '../../../../core/_models'
import {useProfile} from '../../../../core/requests/auth'
import {useState, useMemo} from 'react'
import {useQueryClient} from 'react-query'
import LoadingOverlayWrapper from '../../../../components/LoadingOverlayWrapper'

const StopRegistrationWidget = ({widget}: WidgetProps) => (
  <div className='d-flex align-items-center justify-content-center h-100'>
    {!widget.operational_event_categories ||
      (widget.operational_event_categories.length === 0 ? (
        <>
          {widget.entity_group === 'asset' && <LogEventButton assetId={widget.entity} />}
          {widget.entity_group === 'production_line' && (
            <LogEventButton productionLineId={widget.entity} />
          )}
        </>
      ) : (
        <LogPreConfiguredEventButton
          entity={widget.entity}
          eventCategories={widget.operational_event_categories}
        />
      ))}
  </div>
)

const LogPreConfiguredEventButton = ({
  entity,
  eventCategories,
}: {
  entity: string
  eventCategories: string[]
}) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const {data: profile} = useProfile()
  const queryClient = useQueryClient()
  const modal = useModalState()

  const {data: eventCategory, isLoading: isEventCategoryLoading} = useEventCategory({
    id: eventCategories.at(0),
  })

  // const preConfiguredCategory =
  //   eventCategory && findSubEventCategoryByPath(eventCategory, eventCategories.slice(1))

  const preConfiguredCategory = useMemo(() => {
    // Return early if eventCategory is undefined
    if (!eventCategory) {
      return undefined
    }

    // Attempt to find the matching sub-event category first
    const foundCategory = findSubEventCategoryByPath(eventCategory, eventCategories.slice(1))

    // If a matching sub-event category is found, return it
    if (foundCategory) {
      return foundCategory
    }

    // If no matching sub-event category is found, check if eventCategory's ID matches the first element of eventCategories
    if (eventCategory && eventCategory._id === eventCategories.at(0)) {
      return eventCategory
    }

    // Return undefined if no conditions are met
    return undefined
  }, [eventCategory, eventCategories])

  const onSubmit = async () => {
    if (!profile?.organization) return
    setIsSubmitting(true)
    try {
      await createEventLog({
        organization: profile.organization._id,
        entity: entity,
        categories: eventCategories,
      })

      await queryClient.invalidateQueries('eventLogs')
    } catch (error) {
      console.error(error)
    } finally {
      setIsSubmitting(false)
      modal.close()
    }
  }

  return (
    <>
      <button type='button' className='btn btn-lg btn-secondary' onClick={modal.open}>
        + Event
      </button>

      <Modal
        show={modal.isOpen}
        tabIndex={-1}
        aria-hidden='true'
        size='lg'
        dialogClassName='modal-dialog modal-dialog-centered'
        onHide={modal.close}
        // backdrop={loading ? 'static' : true}
      >
        <LoadingOverlayWrapper loading={isEventCategoryLoading}>
          <div className='modal-header pb-0 border-0 justify-content-end'>
            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={modal.close}>
              <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
            </div>
          </div>
          <div className='modal-body mx-5 mx-xl-18 pt-0'>
            <h1 className='text-center mb-8'>{`Log ${preConfiguredCategory?.title} Event?`}</h1>

            <div className='d-flex flex-center pt-5'>
              <button className='btn btn-light me-3' onClick={modal.close}>
                Cancel
              </button>
              <button className='btn btn-primary' onClick={onSubmit}>
                Confirm Log Event
              </button>
            </div>
          </div>
        </LoadingOverlayWrapper>
      </Modal>
    </>
  )
}

const findSubEventCategoryByPath = (
  eventCategory: EventCategory,
  subEventCategoryPathIds: string[]
): SubEventCategory | undefined => {
  const findSubEventCategory = (
    subCategories: SubEventCategory[],
    pathIds: string[]
  ): SubEventCategory | undefined => {
    if (pathIds.length === 0) return undefined

    const currentId = pathIds[0]
    const currentSubCategory = subCategories.find((subCat) => subCat._id === currentId)

    if (pathIds.length === 1 || !currentSubCategory) return currentSubCategory
    return findSubEventCategory(currentSubCategory.sub_event_categories, pathIds.slice(1))
  }

  return findSubEventCategory(eventCategory.sub_event_categories, subEventCategoryPathIds)
}

export default StopRegistrationWidget
