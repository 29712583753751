/* eslint-disable react/jsx-no-target-blank */
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useProfile} from '../../../../../app/core/requests/auth'
import {DashboardSidebarMenu} from './DashboardSidebarMenu'

const SidebarMenuMain = () => {
  const {data: profile} = useProfile()

  return (
    <>
      <DashboardSidebarMenu />

      <SidebarMenuItem to='/planner' fontAwesomeIcon='fa-calendar' title='Planner' />

      <SidebarMenuItemWithSub to='' fontAwesomeIcon='fa-clipboard-list' title='Maintenance'>
        <SidebarMenuItem to='/work-orders' fontAwesomeIcon='fa-wrench' title='Work Orders' />
        <SidebarMenuItem to='/plans' fontAwesomeIcon='fa-list' title='Plans' />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to='/organization'
        fontAwesomeIcon='fa-sitemap'
        title='Organization'
        // notification='2'
      />
      {profile?.is_staff && (
        <SidebarMenuItemWithSub to='' title='Staff' fontAwesomeIcon='fa-users'>
          <SidebarMenuItem to='staff/devices' title='Devices' fontAwesomeIcon='fa-desktop' />
          <SidebarMenuItem to='staff/states' title='States' fontAwesomeIcon='fa-image' />
          <SidebarMenuItem to='staff/tasks' title='Tasks' fontAwesomeIcon='fa-list-check' />
        </SidebarMenuItemWithSub>
      )}
    </>
  )
}

export {SidebarMenuMain}
