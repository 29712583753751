import axios, {AxiosError} from 'axios'
import qs from 'qs'
import {useMemo} from 'react'
import {useMutation, UseMutationOptions, useQueries, useQuery, UseQueryOptions} from 'react-query'
import {toast} from 'react-toastify'
import {
  usePaginatedInfiniteQuery,
  UsePaginatedInfiniteQueryOptions,
} from '../../components/pagination/usePaginatedInfiniteQuery'
import usePaginatedQuery from '../../components/pagination/usePaginatedQuery'
import {ICreateArea} from '../../modals/create-area/_models'
import {ICreateAsset} from '../../modals/create-asset/_models'
import {ICreateFactory} from '../../modals/create-factory/_models'
import {ICreateGateway} from '../../modals/create-gateway/_models'
import {ICreatePart} from '../../modals/create-part/_models'
import {ICreatePlacement} from '../../modals/create-placement/_models'
import {ICreatePlc} from '../../modals/create-plc/_models'
import {ICreateProductionLine} from '../../modals/create-production-line/_models'
import {ICreateSection} from '../../modals/create-section/_models'
import {ICreateSensor} from '../../modals/create-sensor/_models'
import {
  Area,
  Asset,
  AssetGraphs,
  AssetsStatus,
  AssetState,
  Axis,
  BaseModel,
  Consumption,
  DeletionConfirmation,
  DeviceState,
  Factory,
  FactoryEntity,
  Gateway,
  Manufacturer,
  ManufacturingProcess,
  OnlineStatus,
  OperationalState,
  OrganizationQueryProps,
  OrganizationRequestParams,
  PaginatedQueryProps,
  PaginatedRequestParams,
  PaginatedResponse,
  Part,
  PartModel,
  PartType,
  Placement,
  ProductionLine,
  Section,
  Sensor,
  SensorType,
  Plc,
  StandbyStats,
  PlacementFFT,
  PlacementFFTSearch,
  PlacementGraphs,
  PowerFactor,
  ReferenceUUID,
  SensorRecord,
  TimedValue,
  Timespan,
  Tag,
} from '../_models'
const FACTORY_API_BASE_URL = process.env.REACT_APP_WA_FACTORY_URL

// ----- FACTORY ----- //

const FACTORIES_URL = `${FACTORY_API_BASE_URL}/factories`

export type UseFactoriesProps = PaginatedQueryProps<Factory & BaseModel> & OrganizationQueryProps

type UseFactoriesRequestParams = PaginatedRequestParams & OrganizationRequestParams

export const fetchFactories = async <T = Factory>(params?: UseFactoriesRequestParams) =>
  (await axios.get<PaginatedResponse<T>>(FACTORIES_URL, {params})).data

export const useFactories = (props: UseFactoriesProps = {}) => {
  const {organization, page, size, options} = props
  const params: UseFactoriesRequestParams = {
    organization,
    page,
    size,
  }
  return useQuery<PaginatedResponse<Factory & BaseModel>, Error>(
    ['factories', ...Object.values(params)],
    async () => fetchFactories(params),
    options
  )
}

export const useFactoriesPaginated = (
  params: UseFactoriesRequestParams = {},
  options?: UsePaginatedInfiniteQueryOptions
) =>
  usePaginatedInfiniteQuery<Factory, typeof fetchFactories>({
    queryKey: ['factories', ...Object.values(params)],
    fetchFn: fetchFactories,
    params,
    enabled: options?.enabled,
  })

type useFactoryProps = {
  id?: string
  options?: UseQueryOptions<Factory & BaseModel, Error>
}

export const useFactory = ({id, options}: useFactoryProps) => {
  return useQuery<Factory & BaseModel, Error>(
    ['factory', id],
    async () => {
      const {data} = await axios.get<Factory & BaseModel>(`${FACTORIES_URL}/${id}`)
      return data
    },
    {enabled: !!id, ...options}
  )
}

export async function createFactory(props: ICreateFactory) {
  const {organization, name, location, currency, fixedPowerPrice} = props
  const data = {
    organization,
    name,
    location,
    currency,
    fixed_power_price: fixedPowerPrice,
  }
  const {data: factory} = await axios.post<Factory & BaseModel>(FACTORIES_URL, data)
  return factory
}

export const updateFactory = async (id: string, data: Partial<Factory>) => {
  const {data: factory} = await axios.put<Factory & BaseModel>(`${FACTORIES_URL}/${id}`, data)
  return factory
}

export const useUpdateFactoryMutation = () =>
  useMutation<Factory, AxiosError, Partial<Factory> & ReferenceUUID>({
    mutationFn: (data) => updateFactory(data._id, data),
    onSuccess: () => {
      toast.success('Factory data was updated')
    },
    onError: (error) => {
      toast.error(`Can't update factory data`)
      console.warn('useUpdateFactoryMutation', error)
    },
  })

export async function deleteFactory(factoryId: string) {
  const {data} = await axios.delete<DeletionConfirmation>(`${FACTORIES_URL}/${factoryId}`)
  return data
}

// ----- AREAS ----- //

const AREAS_URL = `${FACTORY_API_BASE_URL}/areas`

export type UseAreasProps = PaginatedQueryProps<Area & BaseModel> & {
  organization?: string
  factory?: string
}

type UseAreasRequestParams = PaginatedRequestParams & {
  organization?: string
  factory?: string
}

export const fetchAreas = async <T = Area>(params?: UseAreasRequestParams) =>
  (await axios.get<PaginatedResponse<T>>(AREAS_URL, {params})).data

export function useArea(
  areaId?: string,
  {options}: {options?: UseQueryOptions<Area & BaseModel, Error>} = {}
) {
  return useQuery<Area & BaseModel, Error>(
    ['area', areaId],
    async () => {
      const {data} = await axios.get<Area & BaseModel>(`${AREAS_URL}/${areaId}`)
      return data
    },
    {
      enabled: !!areaId,
      ...options,
    }
  )
}

export function useAreas(props: UseAreasProps = {}) {
  const {organization, factory, page, size, options} = props
  const params: UseAreasRequestParams = {
    organization,
    factory,
    page,
    size,
  }
  return useQuery<PaginatedResponse<Area & BaseModel>, Error>(
    ['areas', ...Object.values(params)],
    () => fetchAreas(params),
    options
  )
}

export const useAreasPaginated = (
  params: UseAreasRequestParams = {},
  options?: UsePaginatedInfiniteQueryOptions
) =>
  usePaginatedInfiniteQuery<Area, typeof fetchAreas>({
    queryKey: ['areas', ...Object.values(params)],
    fetchFn: fetchAreas,
    params,
    enabled: options?.enabled,
  })

export async function createArea(props: ICreateArea) {
  const {data} = await axios.post<Area & BaseModel>(AREAS_URL, props)
  return data
}

export const updateArea = async (id: string, data: Partial<Area>) => {
  const {data: area} = await axios.put<Area & BaseModel>(`${AREAS_URL}/${id}`, data)
  return area
}

export const useUpdateAreaMutation = () =>
  useMutation<Area, AxiosError, Partial<Area> & ReferenceUUID>({
    mutationFn: (data) => updateArea(data._id, data),
    onSuccess: () => {
      toast.success('Area data was updated')
    },
    onError: (error) => {
      toast.error(`Can't update area data`)
      console.warn('useUpdateAreaMutation', error)
    },
  })

export async function deleteArea(areaId: string) {
  const {data} = await axios.delete<DeletionConfirmation>(`${AREAS_URL}/${areaId}`)
  return data
}

// ----- PRODUCTION LINES ----- //

const PRODUCTION_LINES_URL = `${FACTORY_API_BASE_URL}/production-lines`

export type UseProductionLinesProps = PaginatedQueryProps<ProductionLine & BaseModel> & {
  factory?: string
  area?: string
  manufacturingType?: ManufacturingProcess
} & OrganizationQueryProps

type UseProductionLinesRequestParams = PaginatedRequestParams & {
  factory?: string
  area?: string
  manufacturing_type?: ManufacturingProcess
} & OrganizationRequestParams

export const fetchProductionLines = async <T = ProductionLine>(
  params?: UseProductionLinesRequestParams
) => (await axios.get<PaginatedResponse<T>>(PRODUCTION_LINES_URL, {params})).data

export function useProductionLines(props: UseProductionLinesProps = {}) {
  const {organization, area, factory, manufacturingType, page, size, options} = props
  const params: UseProductionLinesRequestParams = {
    organization,
    factory,
    area,
    manufacturing_type: manufacturingType,
    page,
    size,
  }
  return useQuery<PaginatedResponse<ProductionLine & BaseModel>, Error>(
    ['productionLines', ...Object.values(params)],
    async () => fetchProductionLines(params),
    options
  )
}

export const useProductionLinesPaginated = (
  params: UseProductionLinesRequestParams = {},
  options?: UsePaginatedInfiniteQueryOptions
) =>
  usePaginatedInfiniteQuery<ProductionLine, typeof fetchProductionLines>({
    queryKey: ['productionLines', ...Object.values(params)],
    fetchFn: fetchProductionLines,
    params,
    enabled: options?.enabled,
  })

export function useProductionLine(
  productionLine?: string,
  {options}: {options?: UseQueryOptions<ProductionLine & BaseModel, Error>} = {}
) {
  return useQuery<ProductionLine & BaseModel, Error>(
    ['productionLine', productionLine],
    async () => {
      const {data} = await axios.get<ProductionLine & BaseModel>(
        `${PRODUCTION_LINES_URL}/${productionLine}`
      )
      return data
    },
    {enabled: !!productionLine, ...options}
  )
}

export async function createProductionLine(props: ICreateProductionLine) {
  const {data} = await axios.post<ProductionLine & BaseModel>(PRODUCTION_LINES_URL, props)
  return data
}

export const updateProductionLine = async (id: string, data: Partial<ProductionLine>) => {
  const {data: productionLine} = await axios.put<ProductionLine & BaseModel>(
    `${PRODUCTION_LINES_URL}/${id}`,
    data
  )
  return productionLine
}

export const useUpdateProductionLineMutation = () =>
  useMutation<ProductionLine, AxiosError, Partial<ProductionLine> & ReferenceUUID>({
    mutationFn: (data) => updateProductionLine(data._id, data),
    onSuccess: () => {
      toast.success('ProductionLine data was updated')
    },
    onError: (error) => {
      toast.error(`Can't update ProductionLine data`)
      console.warn('updateProductionLine', error)
    },
  })

export async function deleteProductionLine(productionLineId: string) {
  const {data} = await axios.delete<DeletionConfirmation>(
    `${PRODUCTION_LINES_URL}/${productionLineId}`
  )
  return data
}

// ----- ENERGY ----- //

type UseProductionLineStandbyStatsProps = {
  productionLine?: string
  startTime?: string
  endTime?: string
  options?: UseQueryOptions<StandbyStats, Error>
}

type UseProductionLineStandbyStatsRequestParams = {
  start_time?: string
  end_time?: string
}

export function useProductionLineStandbyStats(props: UseProductionLineStandbyStatsProps = {}) {
  const {productionLine, startTime, endTime, options} = props
  const params: UseProductionLineStandbyStatsRequestParams = {
    start_time: startTime,
    end_time: endTime,
  }
  return useQuery<StandbyStats, Error>(
    ['productionLineStandbyStats', productionLine, ...Object.values(params)],
    async () => {
      const {data} = await axios.get<StandbyStats>(
        `${PRODUCTION_LINES_URL}/${productionLine}/standby/stats`,
        {params}
      )
      return data
    },
    {enabled: !!productionLine, ...options}
  )
}

type UseProductionLineConsumptionProps = {
  productionLine?: string
  startTime?: string
  endTime?: string
  options?: UseQueryOptions<Consumption, Error>
}

type UseProductionLineConsumptionRequestParams = {
  start_time?: string
  end_time?: string
}

export function useProductionLineConsumption(props: UseProductionLineConsumptionProps = {}) {
  const {productionLine, startTime, endTime, options} = props
  const params: UseProductionLineConsumptionRequestParams = {
    start_time: startTime,
    end_time: endTime,
  }
  return useQuery<Consumption, Error>(
    ['productionLineConsumption', productionLine, ...Object.values(params)],
    async () => {
      const {data} = await axios.get<Consumption>(
        `${PRODUCTION_LINES_URL}/${productionLine}/consumption`,
        {params}
      )
      return data
    },
    {enabled: !!productionLine, ...options}
  )
}

type UseProductionLinePowerConsumptionHistoryProps = {
  productionLine?: string
  startTime?: string
  endTime?: string
  timespan?: Timespan
  multiplier?: number
  options?: UseQueryOptions<TimedValue[], Error>
}

type UseProductionLinePowerConsumptionHistoryRequestParams = {
  start_time?: string
  end_time?: string
  timespan?: Timespan
  multiplier?: number
}

export function useProductionLinePowerConsumptionHistory(
  props: UseProductionLinePowerConsumptionHistoryProps = {}
) {
  const {productionLine, startTime, endTime, timespan, multiplier, options} = props
  const params: UseProductionLinePowerConsumptionHistoryRequestParams = {
    start_time: startTime,
    end_time: endTime,
    timespan,
    multiplier,
  }
  return useQuery<TimedValue[], Error>(
    ['productionLinePowerConsumptionHistory', productionLine, ...Object.values(params)],
    async () => {
      const {data} = await axios.get<TimedValue[]>(
        `${PRODUCTION_LINES_URL}/${productionLine}/power`,
        {params}
      )
      return data
    },
    {enabled: !!productionLine, ...options}
  )
}

type UseProductionLinePowerFactorProps = {
  productionLine?: string
  startTime?: string
  endTime?: string
  options?: UseQueryOptions<PowerFactor[], Error>
}

type UseProductionLinePowerFactorRequestParams = {
  start_time?: string
  end_time?: string
}

export function useProductionLinePowerFactor(props: UseProductionLinePowerFactorProps = {}) {
  const {productionLine, startTime, endTime, options} = props
  const params: UseProductionLinePowerFactorRequestParams = {
    start_time: startTime,
    end_time: endTime,
  }
  return useQuery<PowerFactor[], Error>(
    ['productionLinePowerFactor', productionLine, ...Object.values(params)],
    async () => {
      const {data} = await axios.get<PowerFactor[]>(
        `${PRODUCTION_LINES_URL}/${productionLine}/power-factor`,
        {params}
      )
      return data
    },
    {enabled: !!productionLine, ...options}
  )
}

// ----- SECTIONS ----- //

const SECTIONS_URL = `${FACTORY_API_BASE_URL}/sections`

export const useSection = (
  section?: string,
  options: UseQueryOptions<Section & BaseModel, Error> = {}
) =>
  useQuery<Section & BaseModel, Error>(
    ['section', section],
    async () => {
      const {data} = await axios.get<Section & BaseModel>(`${SECTIONS_URL}/${section}`)
      return data
    },
    {enabled: !!section, ...options}
  )

export type UseSectionsProps = PaginatedQueryProps<Section & BaseModel> & {
  factory?: string
  area?: string
  productionLine?: string
} & OrganizationQueryProps

type UseSectionsRequestParams = PaginatedRequestParams & {
  factory?: string
  area?: string
  production_line?: string
} & OrganizationRequestParams

export const fetchSections = async <T = Section>(params?: UseSectionsRequestParams) =>
  (await axios.get<PaginatedResponse<T>>(SECTIONS_URL, {params})).data

export function useSections(props: UseSectionsProps = {}) {
  const {organization, factory, area, productionLine, page, size, options} = props
  const params: UseSectionsRequestParams = {
    organization,
    factory,
    area,
    production_line: productionLine,
    page,
    size,
  }
  return useQuery<PaginatedResponse<Section & BaseModel>, Error>(
    ['sections', ...Object.values(params)],
    async () => fetchSections(params),
    options
  )
}

export const useSectionsPaginated = (
  params: UseSectionsRequestParams = {},
  options?: UsePaginatedInfiniteQueryOptions
) =>
  usePaginatedInfiniteQuery<Section, typeof fetchSections>({
    queryKey: ['sections', ...Object.values(params)],
    fetchFn: fetchSections,
    params,
    enabled: options?.enabled,
  })

export async function createSection(props: ICreateSection) {
  const {data} = await axios.post<Section & BaseModel>(SECTIONS_URL, props)
  return data
}

export async function deleteSection(sectionId: string) {
  const {data} = await axios.delete<DeletionConfirmation>(`${SECTIONS_URL}/${sectionId}`)
  return data
}

type UseSectionStandbyStatsProps = {
  section?: string
  startTime?: string
  endTime?: string
  options?: UseQueryOptions<StandbyStats, Error>
}

type UseSectionStandbyStatsRequestParams = {
  start_time?: string
  end_time?: string
}

export function useSectionStandbyStats(props: UseSectionStandbyStatsProps = {}) {
  const {section, startTime, endTime, options} = props
  const params: UseSectionStandbyStatsRequestParams = {start_time: startTime, end_time: endTime}
  return useQuery<StandbyStats, Error>(
    ['sectionStandbyStats', section, ...Object.values(params)],
    async () => {
      const {data} = await axios.get<StandbyStats>(`${SECTIONS_URL}/${section}/standby/stats`, {
        params,
      })
      return data
    },
    {enabled: !!section, ...options}
  )
}

type UseSectionConsumption = {
  section?: string
  startTime?: string
  endTime?: string
  options?: UseQueryOptions<Consumption, Error>
}

type UseSectionConsumptionRequestParams = {
  start_time?: string
  end_time?: string
}

export function useSectionConsumption(props: UseSectionConsumption = {}) {
  const {section, startTime, endTime, options} = props
  const params: UseSectionConsumptionRequestParams = {start_time: startTime, end_time: endTime}
  return useQuery<Consumption, Error>(
    ['sectionConsumption', section, ...Object.values(params)],
    async () => {
      const {data} = await axios.get<Consumption>(`${SECTIONS_URL}/${section}/consumption`, {
        params,
      })
      return data
    },
    {enabled: !!section, ...options}
  )
}

type UseSectionPowerConsumptionHistoryProps = {
  section?: string
  startTime?: string
  endTime?: string
  timespan?: Timespan
  multiplier?: number
  options?: UseQueryOptions<TimedValue[], Error>
}

type UseSectionPowerConsumptionHistoryRequestParams = {
  start_time?: string
  end_time?: string
  timespan?: Timespan
  multiplier?: number
}

export function useSectionPowerConsumptionHistory(
  props: UseSectionPowerConsumptionHistoryProps = {}
) {
  const {section, startTime, endTime, timespan, multiplier, options} = props
  const params: UseSectionPowerConsumptionHistoryRequestParams = {
    start_time: startTime,
    end_time: endTime,
    timespan,
    multiplier,
  }
  return useQuery<TimedValue[], Error>(
    ['sectionPowerConsumptionHistory', section, ...Object.values(params)],
    async () => {
      const {data} = await axios.get<TimedValue[]>(`${SECTIONS_URL}/${section}/power`, {params})
      return data
    },
    {enabled: !!section, ...options}
  )
}

// ----- ASSETS ----- //

const ASSETS_URL = `${FACTORY_API_BASE_URL}/assets`

export type UseAssetsProps = PaginatedQueryProps<Asset & BaseModel> & {
  factory?: string
  area?: string
  productionLine?: string
  section?: string
  statuses?: AssetState[]
} & OrganizationQueryProps

type UseAssetsRequestParams = PaginatedRequestParams & {
  factory?: string
  area?: string
  production_line?: string
  section?: string
  statuses?: AssetState[]
} & OrganizationRequestParams

export const fetchAssets = async <T = Asset>(params?: UseAssetsRequestParams) =>
  (await axios.get<PaginatedResponse<T>>(ASSETS_URL, {params})).data

export function useAssets(props: UseAssetsProps = {}) {
  const {organization, factory, area, productionLine, section, statuses, page, size, options} =
    props
  const params: UseAssetsRequestParams = {
    organization,
    factory,
    area,
    production_line: productionLine,
    section,
    statuses,
    page,
    size,
  }
  return useQuery<PaginatedResponse<Asset & BaseModel>, Error>(
    ['assets', ...Object.values(params)],
    () => fetchAssets(params),
    options
  )
}

export const useAssetsPaginated = (
  params: UseAssetsRequestParams = {},
  options?: UsePaginatedInfiniteQueryOptions
) =>
  usePaginatedInfiniteQuery<Asset, typeof fetchAssets>({
    queryKey: ['assets', ...Object.values(params)],
    fetchFn: fetchAssets,
    params,
    enabled: options?.enabled,
  })

const ASSETS_STATUSES_URL = `${FACTORY_API_BASE_URL}/assets/statuses`

type UseAssetsStatusesProps = {
  options?: UseQueryOptions<AssetsStatus[], Error>
  factory?: string
  area?: string
  productionLine?: string
  section?: string
  statuses?: AssetState[]
} & OrganizationRequestParams

export function useAssetsStatuses(props: UseAssetsStatusesProps = {}) {
  const {organization, factory, area, productionLine, section, statuses, options} = props
  const params: UseAssetsRequestParams = {
    organization,
    factory,
    area,
    production_line: productionLine,
    section,
    statuses,
  }
  return useQuery<AssetsStatus[], Error>(
    ['assets', 'statuses', ...Object.values(params)],
    async () => (await axios.get<AssetsStatus[]>(ASSETS_STATUSES_URL)).data,
    options
  )
}

type UseAssetProps = {
  assetId?: string
  options?: UseQueryOptions<Asset & BaseModel, Error>
}

export function useAsset(props: UseAssetProps = {}) {
  const {assetId, options} = props
  return useQuery<Asset & BaseModel, Error>(
    ['asset', assetId],
    async () => {
      const {data} = await axios.get<Asset & BaseModel>(`${ASSETS_URL}/${assetId}`)
      return data
    },
    {enabled: !!assetId, ...options}
  )
}

export async function createAsset(props: ICreateAsset) {
  const {data} = await axios.post<Asset & BaseModel>(ASSETS_URL, props)
  return data
}

export const updateAsset = async (id: string, data: Partial<Asset>) => {
  const {data: asset} = await axios.put<Asset & BaseModel>(`${ASSETS_URL}/${id}`, data)
  return asset
}

export const useUpdateAssetMutation = () =>
  useMutation<Asset, AxiosError, Partial<Asset> & ReferenceUUID>({
    mutationFn: (data) => updateAsset(data._id, data),
    onSuccess: () => {
      toast.success('Asset data was updated')
    },
    onError: (error) => {
      toast.error(`Can't update Asset data`)
      console.warn('updateAsset', error)
    },
  })

export async function deleteAsset(assetId: string) {
  const {data} = await axios.delete<DeletionConfirmation>(`${ASSETS_URL}/${assetId}`)
  return data
}

// ----- CONDITION ----- //

type UseAssetGraphsProps = {
  assetId?: string
  startTime?: string
  endTime?: string
  options?: UseQueryOptions<AssetGraphs, Error>
}

type UseAssetGraphsRequestParams = {
  start_time?: string
  end_time?: string
}

export function useAssetGraphs(props: UseAssetGraphsProps = {}) {
  const {assetId, startTime, endTime, options} = props
  const params: UseAssetGraphsRequestParams = {start_time: startTime, end_time: endTime}
  return useQuery<AssetGraphs, Error>(
    ['assetGraphs', assetId, ...Object.values(params)],
    async () => {
      const {data} = await axios.get<AssetGraphs>(`${ASSETS_URL}/${assetId}/graphs`, {params})
      return data
    },
    {enabled: !!assetId, ...options}
  )
}

type UsePlacementGraphsProps = {
  placementId?: string
  startTime?: string
  endTime?: string
  leadecTenant?: string
  options?: UseQueryOptions<PlacementGraphs, Error>
}

type UsePlacementGraphsRequestParams = {
  start_time?: string
  end_time?: string
  leadec_tenant?: string
}

export function usePlacementGraphs(props: UsePlacementGraphsProps = {}) {
  const {placementId, startTime, endTime, leadecTenant, options} = props
  const params: UsePlacementGraphsRequestParams = {
    start_time: startTime,
    end_time: endTime,
    leadec_tenant: leadecTenant,
  }
  return useQuery<PlacementGraphs, Error>(
    ['placementGraphs', placementId, ...Object.values(params)],
    async () => {
      const {data} = await axios.get<PlacementGraphs>(`${PLACEMENTS_URL}/${placementId}/graphs`, {
        params,
      })
      return data
    },
    {enabled: !!placementId, ...options}
  )
}

type UsePlacementFFTSearchProps = {
  placementId?: string
  axis?: 'X' | 'Y' | 'Z'
  startTime?: string
  endTime?: string
  options?: UseQueryOptions<PlacementFFTSearch[], Error>
}

type UsePlacementFFTSearchParams = {
  axis?: 'X' | 'Y' | 'Z'
  start_time?: string
  end_time?: string
}

export function usePlacementFFTSearch(props: UsePlacementFFTSearchProps) {
  const {placementId, axis, startTime, endTime, options} = props
  const params: UsePlacementFFTSearchParams = {
    axis,
    start_time: startTime,
    end_time: endTime,
  }
  return useQuery<PlacementFFTSearch[], Error>(
    ['usePlacementFFTSearch', placementId, ...Object.values(params)],
    async () =>
      (
        await axios.get<PlacementFFTSearch[]>(`${PLACEMENTS_URL}/${placementId}/fft/search`, {
          params,
        })
      ).data,
    {enabled: !!placementId && !!axis, ...options}
  )
}

type UsePlacementFFTProps = {
  placementId?: string
  axis?: 'X' | 'Y' | 'Z'
  timestamps?: string[]
  startTime?: string
  endTime?: string
  minRPM?: number
  maxRPM?: number
  options?: UseQueryOptions<PlacementFFT[], Error>
}

type UsePlacementFFTParams = {
  axis?: 'X' | 'Y' | 'Z'
  timestamps?: string[]
  start_time?: string
  end_time?: string
  min_rpm?: number
  max_rpm?: number
}

export function usePlacementFFT(props: UsePlacementFFTProps) {
  const {placementId, axis, timestamps, startTime, endTime, minRPM, maxRPM, options} = props
  const params: UsePlacementFFTParams = {
    axis,
    timestamps,
    start_time: startTime,
    end_time: endTime,
    min_rpm: minRPM,
    max_rpm: maxRPM,
  }
  return useQuery<PlacementFFT[], Error>(
    ['usePlacementFFT', placementId, ...Object.values(params)],
    async () =>
      (
        await axios.get<PlacementFFT[]>(`${PLACEMENTS_URL}/${placementId}/fft`, {
          params,
          paramsSerializer: (params) =>
            qs.stringify(params, {arrayFormat: 'repeat', skipNulls: true}),
        })
      ).data,
    {enabled: !!placementId && !!axis, ...options}
  )
}

// ----- PARTS ----- //

const PARTS_URL = `${FACTORY_API_BASE_URL}/parts`

export type UsePartsProps = PaginatedQueryProps<Part & BaseModel> & {
  factory?: string
  area?: string
  productionLine?: string
  section?: string
  asset?: string
  type?: PartType
  manufacturer?: string
  modelNumber?: string
} & OrganizationQueryProps

type UsePartsRequestParams = PaginatedRequestParams & {
  factory?: string
  area?: string
  production_line?: string
  section?: string
  asset?: string
  type?: PartType
  manufacturer?: string
  model_number?: string
} & OrganizationRequestParams

export const fetchParts = async <T = Part>(params?: UsePartsRequestParams) =>
  (await axios.get<PaginatedResponse<T>>(PARTS_URL, {params})).data

export function useParts(props: UsePartsProps = {}) {
  const {
    organization,
    factory,
    area,
    productionLine,
    section,
    asset,
    type,
    manufacturer,
    modelNumber,
    page,
    size,
    options,
  } = props
  const params: UsePartsRequestParams = {
    organization,
    factory,
    area,
    production_line: productionLine,
    section,
    asset,
    type,
    manufacturer,
    model_number: modelNumber,
    page,
    size,
  }
  return useQuery<PaginatedResponse<Part & BaseModel>, Error>(
    ['parts', ...Object.values(params)],
    async () => fetchParts(params),
    options
  )
}

export const usePartsPaginated = (
  params: UsePartsRequestParams = {},
  options?: UsePaginatedInfiniteQueryOptions
) =>
  usePaginatedInfiniteQuery<Part, typeof fetchParts>({
    queryKey: ['parts', ...Object.values(params)],
    fetchFn: fetchParts,
    params,
    enabled: options?.enabled,
  })

export async function createPart(props: ICreatePart) {
  const {data} = await axios.post<Part & BaseModel>(PARTS_URL, props)
  return data
}

export async function deletePart(partId: string) {
  const {data} = await axios.delete<DeletionConfirmation>(`${PARTS_URL}/${partId}`)
  return data
}

// ----- PLACEMENTS ----- //

const PLACEMENTS_URL = `${FACTORY_API_BASE_URL}/placements`

export const usePlacement = (
  placement?: string,
  options: UseQueryOptions<Placement & BaseModel, Error> = {}
) =>
  useQuery<Placement & BaseModel, Error>(
    ['placement', placement],
    async () => {
      const {data} = await axios.get<Placement & BaseModel>(`${PLACEMENTS_URL}/${placement}`)
      return data
    },
    {enabled: !!placement, ...options}
  )

export type UsePlacementsProps = PaginatedQueryProps<Placement & BaseModel> & {
  factory?: string
  area?: string
  productionLine?: string
  section?: string
  asset?: string
  part?: string
  hasSensorMounted?: boolean
} & OrganizationQueryProps

type UsePlacementsRequestParams = PaginatedRequestParams & {
  factory?: string
  area?: string
  production_line?: string
  section?: string
  asset?: string
  part?: string
  has_sensor_mounted?: boolean
} & OrganizationRequestParams

export const fetchPlacements = async <T = Placement>(params?: UsePlacementsRequestParams) =>
  (await axios.get<PaginatedResponse<T>>(PLACEMENTS_URL, {params})).data

export function usePlacements(props: UsePlacementsProps = {}) {
  const {
    organization,
    factory,
    area,
    productionLine,
    section,
    asset,
    part,
    page,
    hasSensorMounted,
    size,
    options,
  } = props
  const params: UsePlacementsRequestParams = {
    organization,
    factory,
    area,
    production_line: productionLine,
    section,
    asset,
    part,
    has_sensor_mounted: hasSensorMounted,
    page,
    size,
  }
  return useQuery<PaginatedResponse<Placement & BaseModel>, Error>(
    ['placements', ...Object.values(params)],
    async () => fetchPlacements(params),
    options
  )
}

export const usePlacementsPaginated = (
  params: UsePlacementsRequestParams = {},
  options?: UsePaginatedInfiniteQueryOptions
) =>
  usePaginatedInfiniteQuery<Placement, typeof fetchPlacements>({
    queryKey: ['placements', ...Object.values(params)],
    fetchFn: fetchPlacements,
    params,
    enabled: options?.enabled,
  })

type UsePlacementProps = {
  id?: string
  options?: UseQueryOptions<Placement & BaseModel, Error>
}

export async function createPlacement(props: ICreatePlacement) {
  const {data} = await axios.post<Placement & BaseModel>(PLACEMENTS_URL, props)
  return data
}

export const updatePlacement = async (id: string, data: Partial<Placement>) => {
  const {data: placement} = await axios.put<Placement & BaseModel>(`${PLACEMENTS_URL}/${id}`, data)
  return placement
}

export const useUpdatePlacementMutation = () =>
  useMutation<Placement, AxiosError, Partial<Placement> & ReferenceUUID>({
    mutationFn: (data) => updatePlacement(data._id, data),
    onSuccess: () => {
      toast.success('Placement data was updated')
    },
    onError: (error) => {
      toast.error(`Can't update Placement data`)
      console.warn('updatePlacement', error)
    },
  })

export async function deletePlacement(placementId: string) {
  const {data} = await axios.delete<DeletionConfirmation>(`${PLACEMENTS_URL}/${placementId}`)
  return data
}

type UsePlacementStandbyStatsProps = {
  placement?: string
  startTime?: string
  endTime?: string
  options?: UseQueryOptions<StandbyStats, Error>
}

type UsePlacementStandbyStatsRequestParams = {
  start_time?: string
  end_time?: string
}

export function usePlacementStandbyStats(props: UsePlacementStandbyStatsProps = {}) {
  const {placement, startTime, endTime, options} = props
  const params: UsePlacementStandbyStatsRequestParams = {start_time: startTime, end_time: endTime}
  return useQuery<StandbyStats, Error>(
    ['placementStandbyStats', placement, ...Object.values(params)],
    async () => {
      const {data} = await axios.get<StandbyStats>(`${PLACEMENTS_URL}/${placement}/standby/stats`, {
        params,
      })
      return data
    },
    {enabled: !!placement, ...options}
  )
}

type UsePlacementConsumptionProps = {
  placement?: string
  startTime?: string
  endTime?: string
  options?: UseQueryOptions<Consumption, Error>
}

type UsePlacementConsumptionRequestParams = {
  start_time?: string
  end_time?: string
}

export function usePlacementConsumption(props: UsePlacementConsumptionProps = {}) {
  const {placement, startTime, endTime, options} = props
  const params: UsePlacementConsumptionRequestParams = {
    start_time: startTime,
    end_time: endTime,
  }
  return useQuery<Consumption, Error>(
    ['placementConsumption', placement, ...Object.values(params)],
    async () => {
      const {data} = await axios.get<Consumption>(`${PLACEMENTS_URL}/${placement}/consumption`, {
        params,
      })
      return data
    },
    {enabled: !!placement, ...options}
  )
}

type UsePlacementPowerConsumptionHistoryProps = {
  placement?: string
  startTime?: string
  endTime?: string
  timespan?: Timespan
  multiplier?: number
  options?: UseQueryOptions<TimedValue[], Error>
}

type UsePlacementPowerConsumptionHistoryRequestParams = {
  start_time?: string
  end_time?: string
  timespan?: Timespan
  multiplier?: number
}

export function usePlacementPowerConsumptionHistory(
  props: UsePlacementPowerConsumptionHistoryProps = {}
) {
  const {placement, startTime, endTime, timespan, multiplier, options} = props
  const params: UsePlacementPowerConsumptionHistoryRequestParams = {
    start_time: startTime,
    end_time: endTime,
    timespan,
    multiplier,
  }
  return useQuery<TimedValue[], Error>(
    ['placementPowerConsumptionHistory', placement, ...Object.values(params)],
    async () => {
      const {data} = await axios.get<TimedValue[]>(`${PLACEMENTS_URL}/${placement}/power`, {params})
      return data
    },
    {enabled: !!placement, ...options}
  )
}

// ----- SENSORS ----- //

const SENSORS_URL = `${FACTORY_API_BASE_URL}/sensors`

export type UseSensorsProps = PaginatedQueryProps<Sensor & BaseModel> & {
  factory?: string
  area?: string
  productionLine?: string
  section?: string
  asset?: string
  part?: string
  plc?: string
  placement?: string
  manufacturer?: Manufacturer
  model?: PartModel
  dataType?: SensorType
  trackScrap?: boolean
  state?: DeviceState
} & OrganizationQueryProps

type UseSensorsRequestParams = PaginatedRequestParams & {
  factory?: string
  area?: string
  production_line?: string
  section?: string
  asset?: string
  part?: string
  plc?: string
  placement?: string
  manufacturer?: Manufacturer
  model?: PartModel
  data_type?: string
  track_scrap?: boolean
  state?: DeviceState
} & OrganizationRequestParams

export const fetchSensors = async <T = Sensor>(params?: UseSensorsRequestParams) =>
  (await axios.get<PaginatedResponse<T>>(SENSORS_URL, {params})).data

export function useSensors(props: UseSensorsProps = {}) {
  const {
    organization,
    factory,
    area,
    productionLine,
    section,
    asset,
    part,
    plc,
    placement,
    manufacturer,
    model,
    dataType,
    trackScrap,
    state,
    page,
    size,
    options,
  } = props
  const params: UseSensorsRequestParams = {
    organization,
    factory,
    area,
    production_line: productionLine,
    section,
    asset,
    part,
    plc,
    placement,
    manufacturer,
    model,
    data_type: dataType,
    track_scrap: trackScrap,
    state,
    page,
    size,
  }
  return useQuery<PaginatedResponse<Sensor & BaseModel>, Error>(
    ['sensors', ...Object.values(params)],
    async () => fetchSensors(params),
    options
  )
}

export const useSensorsPaginated = (
  params: UseSensorsRequestParams = {},
  options?: UsePaginatedInfiniteQueryOptions
) =>
  usePaginatedInfiniteQuery<Sensor, typeof fetchSensors>({
    queryKey: ['sensors', ...Object.values(params)],
    fetchFn: fetchSensors,
    params,
    enabled: options?.enabled,
  })

const SENSORS_STATUSES_URL = `${FACTORY_API_BASE_URL}/sensors/statuses`

type UseSensorsStatusesProps = {
  factory?: string
  area?: string
  productionLine?: string
  section?: string
  asset?: string
  part?: string
  plc?: string
  placement?: string
  manufacturer?: Manufacturer
  model?: PartModel
  dataType?: SensorType
  trackScrap?: boolean
  state?: DeviceState
  options?: UseQueryOptions<OnlineStatus[], Error>
} & OrganizationQueryProps

export function useSensorsStatuses(props: UseSensorsStatusesProps = {}) {
  const {
    organization,
    factory,
    area,
    productionLine,
    section,
    asset,
    part,
    plc,
    placement,
    manufacturer,
    model,
    dataType,
    trackScrap,
    state,
    options,
  } = props
  const params: UseSensorsRequestParams = {
    organization,
    factory,
    area,
    production_line: productionLine,
    section,
    asset,
    part,
    plc,
    placement,
    manufacturer,
    model,
    data_type: dataType,
    track_scrap: trackScrap,
    state,
  }
  return useQuery<OnlineStatus[], Error>(
    ['sensors', 'statuses', ...Object.values(params)],
    async () => (await axios.get<OnlineStatus[]>(SENSORS_STATUSES_URL, {params})).data,
    options
  )
}

export const fetchSensor = async (sensor: string) =>
  (await axios.get<Sensor & BaseModel>(`${SENSORS_URL}/${sensor}`)).data

export function useSensor(
  sensor?: string,
  {options}: {options?: UseQueryOptions<Sensor & BaseModel, Error>} = {}
) {
  return useQuery<Sensor & BaseModel, Error>(
    ['sensor', sensor],
    () => fetchSensor(sensor as string),
    {enabled: !!sensor, ...options}
  )
}

export const useSensorQueries = (
  sensors: string[] = [],
  options: UseQueryOptions<Sensor & BaseModel, Error> = {
    enabled: true,
  }
) => {
  const queries = useQueries<UseQueryOptions<Sensor & BaseModel, Error>[]>(
    (sensors || []).map((sensor) => ({
      queryKey: ['sensor', sensor, options?.enabled],
      queryFn: async () => await fetchSensor(sensor),
      options,
    }))
  )

  const {isLoading, items} = useMemo(() => {
    const isLoading = queries.map((query) => query.isLoading).includes(true)
    const items = queries.filter((query) => !!query.data).map((query) => query.data)
    return {
      isLoading,
      items,
    }
  }, [queries])

  return {
    isLoading,
    items,
  }
}

type UpdateSensorProps = {
  sensor: string
  placement?: string
  dataTypes?: SensorType[]
  manufacturer?: string
  model?: PartModel
  x?: Axis
  y?: Axis
  z?: Axis
  isVertical?: boolean
  wakeupThreshold?: number
  trackScrap?: boolean
  includeInPowerStopDetection?: boolean
  isDemo?: boolean
  offlineToleranceMinutes?: number
}

type UpdateSensorRequestParams = {
  placement?: string
  data_types?: SensorType[]
  manufacturer?: string
  model?: PartModel
  x?: Axis
  y?: Axis
  z?: Axis
  is_vertical?: boolean
  wakeup_threshold?: number
  track_scrap?: boolean
  include_in_power_stop_detection?: boolean
  is_demo?: boolean
  offline_tolerance_minutes?: number
}

export async function updateSensor(props: UpdateSensorProps) {
  const {
    sensor,
    placement,
    dataTypes,
    manufacturer,
    model,
    x,
    y,
    z,
    isVertical,
    wakeupThreshold,
    trackScrap,
    includeInPowerStopDetection,
    isDemo,
    offlineToleranceMinutes,
  } = props
  const params: UpdateSensorRequestParams = {
    placement,
    data_types: dataTypes,
    manufacturer,
    model,
    x,
    y,
    z,
    is_vertical: isVertical,
    wakeup_threshold: wakeupThreshold,
    track_scrap: trackScrap,
    include_in_power_stop_detection: includeInPowerStopDetection,
    is_demo: isDemo,
    offline_tolerance_minutes: offlineToleranceMinutes,
  }
  const {data} = await axios.put<Sensor & BaseModel>(`${SENSORS_URL}/${sensor}`, params)
  return data
}

export async function createSensor(props: ICreateSensor) {
  const {data} = await axios.post<Sensor>(SENSORS_URL, props)
  return data
}

export async function deleteSensor(sensorId: string) {
  const {data} = await axios.delete<DeletionConfirmation>(`${SENSORS_URL}/${sensorId}`)
  return data
}

// ----- GATEWAYS ----- //

const GATEWAYS_URL = `${FACTORY_API_BASE_URL}/gateways`

export type UseGatewaysProps = PaginatedQueryProps<Gateway & BaseModel> & {
  factory?: string
  state?: string
  area?: string
} & OrganizationQueryProps

type UseGatewaysRequestParams = PaginatedRequestParams & {
  factory?: string
  state?: string
  area?: string
} & OrganizationRequestParams

export const fetchGateways = async <T = Gateway>(params?: UseGatewaysRequestParams) =>
  (await axios.get<PaginatedResponse<T>>(GATEWAYS_URL, {params})).data

export function useGateways(props: UseGatewaysProps = {}) {
  const {organization, factory, state, area, page, size, options} = props
  const params: UseGatewaysRequestParams = {
    state,
    organization,
    factory,
    area,
    page,
    size,
  }
  return useQuery<PaginatedResponse<Gateway & BaseModel>, Error>(
    ['gateways', ...Object.values(params)],
    async () => fetchGateways(params),
    options
  )
}

export const useGatewaysPaginated = (
  params: UseGatewaysRequestParams = {},
  options?: UsePaginatedInfiniteQueryOptions
) =>
  usePaginatedInfiniteQuery<Gateway, typeof fetchGateways>({
    queryKey: ['gateways', ...Object.values(params)],
    fetchFn: fetchGateways,
    params,
    enabled: options?.enabled,
  })

const GATEWAYS_STATUSES_URL = `${FACTORY_API_BASE_URL}/gateways/statuses`

type UseGatewaysStatusesProps = {
  factory?: string
  state?: string
  area?: string
  options?: UseQueryOptions<OnlineStatus[], Error>
} & OrganizationQueryProps

export function useGatewaysStatuses(props: UseGatewaysStatusesProps = {}) {
  const {organization, factory, state, area, options} = props
  const params: UseGatewaysRequestParams = {
    state,
    organization,
    factory,
    area,
  }
  return useQuery<OnlineStatus[], Error>(
    ['gateways', 'statuses', ...Object.values(params)],
    async () => (await axios.get<OnlineStatus[]>(GATEWAYS_STATUSES_URL, {params})).data,
    options
  )
}

export async function createGateway(props: ICreateGateway) {
  const {data} = await axios.post<Gateway>(GATEWAYS_URL, props)
  return data
}

export async function deleteGateway(gatewayId: string) {
  const {data} = await axios.delete<DeletionConfirmation>(`${GATEWAYS_URL}/${gatewayId}`)
  return data
}

// ----- PLCS ----- //

const PLCS_URL = `${FACTORY_API_BASE_URL}/programmable-logic-controllers`

type UsePlcsProps = PaginatedQueryProps<Plc & BaseModel> & {
  factory?: string
  area?: string
  productionLine?: string
  section?: string
  asset?: string
} & OrganizationQueryProps

type UsePlcsRequestParams = PaginatedRequestParams & {
  factory?: string
  area?: string
  production_line?: string
  section?: string
  asset?: string
} & OrganizationRequestParams

export const fetchPlcs = async <T = Plc>(params?: UsePlcsRequestParams) =>
  (await axios.get<PaginatedResponse<T>>(PLCS_URL, {params})).data

export function usePlcs(props: UsePlcsProps = {}) {
  const {organization, factory, area, productionLine, section, asset, page, size, options} = props
  const params: UsePlcsRequestParams = {
    organization,
    factory,
    area,
    production_line: productionLine,
    section,
    asset,
    page,
    size,
  }
  return useQuery<PaginatedResponse<Plc & BaseModel>, Error>(
    ['plcs', ...Object.values(params)],
    async () => fetchPlcs(params),
    options
  )
}

export const usePlcsPaginated = (
  params: UsePlcsRequestParams = {},
  options?: UsePaginatedInfiniteQueryOptions
) =>
  usePaginatedInfiniteQuery<Plc, typeof fetchPlcs>({
    queryKey: ['plcs', ...Object.values(params)],
    fetchFn: fetchPlcs,
    params,
    enabled: options?.enabled,
  })

export async function createPlc(props: ICreatePlc) {
  const {data} = await axios.post<Plc & BaseModel>(PLCS_URL, props)
  return data
}

export async function deletePlc(plcId: string) {
  const {data} = await axios.delete<DeletionConfirmation>(`${PLCS_URL}/${plcId}`)
  return data
}

// ----- FACTORY ENTITIES ----- //

const FACTORY_ENTITIES_URL = `${FACTORY_API_BASE_URL}/factory-entities`

type UseFactoryEntitiesProps = PaginatedQueryProps<FactoryEntity> & {
  entityIds?: string[]
}

type UseFactoryEntitiesRequestParams = PaginatedRequestParams & {
  uuids?: string[]
}

export function useFactoryEntities(props: UseFactoryEntitiesProps = {}) {
  const {entityIds, page, size, options} = props
  const params: UseFactoryEntitiesRequestParams = {uuids: entityIds, page, size}
  return useQuery<PaginatedResponse<FactoryEntity>, Error>(
    ['factoryEntities', ...Object.values(params)],
    async () =>
      (
        await axios.get<PaginatedResponse<FactoryEntity>>(FACTORY_ENTITIES_URL, {
          params,
          paramsSerializer: (params) =>
            qs.stringify(params, {arrayFormat: 'repeat', skipNulls: true}),
        })
      ).data,
    options
  )
}

type SearchFactoryEntitiesProps = PaginatedQueryProps<FactoryEntity> & {
  name: string
  signal?: AbortSignal
}

type SearchFactoryEntitiesRequestParams = PaginatedRequestParams & {
  name: string
}

export async function searchFactoryEntities(props: SearchFactoryEntitiesProps) {
  const {name, signal, page, size, options} = props
  const params: SearchFactoryEntitiesRequestParams = {name, page, size}
  const {data} = await axios.get<PaginatedResponse<FactoryEntity>>(
    `${FACTORY_ENTITIES_URL}/search`,
    {
      params,
      cancelToken: new axios.CancelToken((c) => {
        if (signal) signal.addEventListener('abort', () => c())
      }),
    }
  )
  return data
}

export async function pushImageToGallery(entity: string, file: File) {
  const formData = new FormData()
  formData.append('image', file)
  const {data} = await axios.post(`${FACTORY_ENTITIES_URL}/${entity}/gallery`, formData)
  return data
}

export async function wipeGallery(entity: string) {
  const {data} = await axios.delete(`${FACTORY_ENTITIES_URL}/${entity}/gallery`)
  return data
}

export async function setImageAsCover(entity: string, image: number) {
  const {data} = await axios.put(`${FACTORY_ENTITIES_URL}/${entity}/gallery/${image}/cover`)
  return data
}

export async function deleteImageFromGallery(entity: string, image: number) {
  const {data} = await axios.delete(`${FACTORY_ENTITIES_URL}/${entity}/gallery/${image}`)
  return data
}

export interface ConsumptionByCategory {
  consumptions: {
    [key: OperationalState]: {consumption: number; time: number}
  }
}

interface ConsumptionByCategoryParams {
  placement_uuids: string[]
  master_placement_uuid: string
  operational_intervals: string[]
  operational_interval_mins?: (number | null)[]
  operational_interval_maxs?: (number | null)[]
  start_time: string | null
  end_time: string | null
}

export const fetchProductionLineConsumptionByCategory = async (
  entity: string,
  params: ConsumptionByCategoryParams
) => {
  const {data} = await axios.get<ConsumptionByCategory>(
    `${PRODUCTION_LINES_URL}/${entity}/consumption-by-category`,
    {
      params,
      paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat', skipNulls: true}),
    }
  )
  return data
}

export const useProductionLineConsumptionByCategory = (
  productionLine: string,
  params: ConsumptionByCategoryParams,
  options: UseQueryOptions<ConsumptionByCategory, Error> = {}
) =>
  useQuery<ConsumptionByCategory, Error>(
    ['production-lines-consumption-by-category', productionLine, ...Object.values(params)],
    () => fetchProductionLineConsumptionByCategory(productionLine, params),
    {enabled: !!productionLine, ...options}
  )

// ----- TAGS ----- //

const TAGS_URL = `${FACTORY_API_BASE_URL}/tags`

type UseTagsProps = PaginatedQueryProps<Tag & BaseModel> & {
  organization?: string
  entities?: string[]
}

type UseTagsRequestParams = PaginatedRequestParams & {
  organization?: string
  entities?: string[]
}
export const fetchTags = async <T = Tag & BaseModel>(params?: UseTagsRequestParams) =>
  (
    await axios.get<PaginatedResponse<T>>(TAGS_URL, {
      params,
      paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat', skipNulls: true}),
    })
  ).data

export function useTags(props: UseTagsProps = {}) {
  const {organization, entities, page, size, options} = props
  const params: UseTagsRequestParams = {organization, entities, page, size}
  return useQuery<PaginatedResponse<Tag & BaseModel>, Error>(
    ['tags', ...Object.values(params)],
    async () => fetchTags(params),
    options
  )
}

export function useTag({id}: {id?: string}) {
  return useQuery<Tag & BaseModel, Error>(
    ['tag', id],
    async () => {
      const {data} = await axios.get<Tag & BaseModel>(`${TAGS_URL}/${id}`)
      return data
    },
    {enabled: !!id}
  )
}

export const useTagsPaginated = (
  params: UseTagsRequestParams = {},
  options?: UsePaginatedInfiniteQueryOptions
) =>
  usePaginatedInfiniteQuery<Tag, typeof fetchTags>({
    queryKey: ['tags', ...Object.values(params)],
    fetchFn: fetchTags,
    params,
    enabled: options?.enabled,
  })

type CreateTagProps = {
  key: string
  value: string
  entity?: string
}

export async function createTag(props: CreateTagProps) {
  const {data} = await axios.post<Tag & BaseModel>(TAGS_URL, props)
  return data
}

type UpdateTagProps = {
  id: string
  key?: string
  value?: string
  entity?: string
}

export async function updateTag(props: UpdateTagProps) {
  const {id, ...data} = props
  const {data: tag} = await axios.put<Tag & BaseModel>(`${TAGS_URL}/${id}`, data)
  return tag
}

export async function deleteTag(tagId: string) {
  const {data} = await axios.delete<DeletionConfirmation>(`${TAGS_URL}/${tagId}`)
  return data
}
