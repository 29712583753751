import {
  usePlacementStandbyStats,
  useProductionLineStandbyStats,
  useSectionStandbyStats,
} from '../../../../core/requests/factory'
import {startTimeFromIso8601Duration} from '../../../../core/time-util'
import useProductProcessedDivider from './useProductProcessedDivider'
import QuantityWidget from './utility-widgets/QuantityWidget'
import {WidgetProps} from './Widget'

const StandbyConsumptionWidget = ({widget}: WidgetProps) => {
  const startTime = widget.dynamic_time_range
    ? startTimeFromIso8601Duration(widget.dynamic_time_range)
    : widget.start_time
  const endTime = widget.dynamic_time_range ? undefined : widget.end_time

  const divider = useProductProcessedDivider({widget})

  const {data: productionLinestandbyStats} = useProductionLineStandbyStats({
    productionLine: widget.entity,
    startTime,
    endTime,
    options: {enabled: widget.entity_group === 'production_line', refetchOnWindowFocus: false},
  })

  const {data: sectionStandbyStats} = useSectionStandbyStats({
    section: widget.entity,
    startTime,
    endTime,
    options: {enabled: widget.entity_group === 'section', refetchOnWindowFocus: false},
  })

  const {data: placementStandbyStats} = usePlacementStandbyStats({
    placement: widget.entity,
    startTime,
    endTime,
    options: {enabled: widget.entity_group === 'placement', refetchOnWindowFocus: false},
  })

  let energy
  switch (widget.entity_group) {
    case 'production_line':
      energy = productionLinestandbyStats?.energy
      break
    case 'section':
      energy = sectionStandbyStats?.energy
      break
    case 'placement':
      energy = placementStandbyStats?.energy
      break
    default:
      break
  }

  return (
    <QuantityWidget value={!!energy && divider ? (energy / divider).toFixed(2) : 0} unit='kWh' />
  )
}

export default StandbyConsumptionWidget
