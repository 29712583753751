import React from 'react'
import Flatpickr from 'react-flatpickr'
import {Sensor} from '../../../../core/_models'
import {fftChartConfig} from '../../core/_chartConfigs/fftChartConfig'
import {GraphBare} from '../GraphBare'
import {usePlacementFFT, usePlacementFFTSearch} from '../../../../core/requests/factory'

interface FFTGraphProps {
  sensor: Sensor
  loading?: boolean
}

const FFTGraph = ({sensor, loading = false}: FFTGraphProps) => {
  const [timestamps, setTimestamps] = React.useState<string[]>([])
  const [axis, setAxis] = React.useState<'X' | 'Y' | 'Z'>('X')
  const [startTime, setStartTime] = React.useState<Date>()
  const [endTime, setEndTime] = React.useState<Date>()

  const {data: fftSearchData, isLoading: isPlacementFFTSearchLoading} = usePlacementFFTSearch({
    placementId: sensor.placement._id,
    axis,
    startTime: startTime?.toISOString(),
    endTime: endTime?.toISOString(),
  })

  const {data: fftData = [], isLoading: isPlacementFFTLoading} = usePlacementFFT({
    placementId: sensor.placement._id,
    axis,
    timestamps,
    options: {
      enabled: timestamps.length > 0,
    },
  })

  const fftSearchComponent = (
    <div className={`overflow-scroll`} style={{height: 400, width: 250}}>
      <ul className={`d-flex flex-column`}>
        {fftSearchData?.map((fft, index) => {
          const date = new Date(fft.time)
          return (
            <li
              key={index}
              role='button'
              onClick={() => setTimestamps([fft.time])}
              className={`d-flex align-items-center py-2 ${
                timestamps.indexOf(fft.time) > -1 ? 'bg-secondary' : ''
              }`}
            >
              <span className={`bullet me-5`}></span>
              {date.toLocaleDateString()} {date.toLocaleTimeString()}
            </li>
          )
        })}
      </ul>
    </div>
  )

  const isLoading = loading || isPlacementFFTSearchLoading || isPlacementFFTLoading
  const graphComponent = React.useMemo(
    () => (
      <GraphBare
        key={`fftChart_${sensor.device_id}_${axis}_${timestamps[0]}`}
        height='400px'
        data={fftData}
        chartId={`fftChart`}
        configureGraph={fftChartConfig}
        loading={isLoading}
      />
    ),
    [sensor.device_id, axis, timestamps, fftData, isLoading]
  )

  return (
    <>
      <div className='mb-10 d-flex justify-content-between'>
        <div className='d-flex mt-2'>
          <Flatpickr
            data-enable-time
            className='form-control form-control-solid ps-12'
            placeholder={`Select start time`}
            options={{dateFormat: 'd, M Y, H:i', time_24hr: true, static: true}}
            value={startTime}
            onChange={(date) => setStartTime(date[0])}
          />
          <Flatpickr
            data-enable-time
            className='form-control form-control-solid ps-12 ms-2'
            placeholder={`Select end time`}
            options={{dateFormat: 'd, M Y, H:i', time_24hr: true, static: true}}
            value={endTime}
            onChange={(date) => setEndTime(date[0])}
          />
        </div>
        <div className='d-flex justify-content-end'>
          {['X', 'Y', 'Z'].map((label) => (
            <div
              key={label}
              className='ms-5 form-check form-check-custom form-check-solid form-check-sm'
            >
              <input
                className='form-check-input'
                type='radio'
                value={label}
                id='flexRadioSm'
                checked={label === axis}
                onChange={(e) => setAxis(e.target.value as 'X' | 'Y' | 'Z')}
              />
              <label className='form-check-label' htmlFor='flexRadioSm'>
                {label}
              </label>
            </div>
          ))}
        </div>
      </div>
      <div className={`d-flex`}>
        {fftSearchComponent}
        {graphComponent}
      </div>
    </>
  )
}

export default FFTGraph
