import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import {TimedValue} from '../../../../core/_models'
import {showNoDataDisplay} from '../../../../core/chart-util'

export function anomalyChartConfig(chartId: string, data: TimedValue[]) {
  const root = am5.Root.new(chartId)
  root.setThemes([am5themes_Animated.new(root)])

  const minValue = -0.5
  const maxValue = 0.5
  const stepValue = (maxValue - minValue) / 4 - 0.1

  const newData = data.map((d) => {
    return {
      date: new Date(d.time).getTime(),
      value: d.value,
    }
  })

  root.dateFormatter.setAll({
    dateFormat: "yyyy.MM.dd 'at' HH:mm:ss",
    dateFields: ['valueX'],
  })

  // Create chart
  // https://www.amcharts.com/docs/v5/charts/xy-chart/
  let chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelY: 'zoomXY',
      pinchZoomX: true,
      pinchZoomY: true,
    })
  )

  chart.get('colors')?.set('step', 2)

  // Create axes
  // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
  let xAxis = chart.xAxes.push(
    am5xy.DateAxis.new(root, {
      baseInterval: {timeUnit: 'minute', count: 1},
      renderer: am5xy.AxisRendererX.new(root, {minGridDistance: 50}),
      tooltip: am5.Tooltip.new(root, {}),
    })
  )

  let yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {}),
      min: -stepValue * 4,
      max: stepValue * 4,
      strictMinMax: true,
    })
  )

  var rendererY = yAxis.get('renderer')
  var rendererX = xAxis.get('renderer')
  rendererY.grid.template.set('forceHidden', true)
  rendererY.labels.template.set('forceHidden', true)
  rendererX.grid.template.set('forceHidden', true)

  function createRange(value: number, axis: am5xy.ValueAxis<am5xy.AxisRenderer>, label: string) {
    let rangeDataItem = axis.makeDataItem({
      value: value,
    })

    let range = axis.createAxisRange(rangeDataItem)

    range.get('label')?.setAll({
      forceHidden: false,
      text: label,
    })

    range.get('grid')?.setAll({
      forceHidden: false,
      strokeOpacity: 0.2,
      location: 1,
    })
  }

  createRange(-stepValue * 4, yAxis, 'Worse')
  createRange(-stepValue * 2, yAxis, '')
  createRange(stepValue * 2, yAxis, '')
  createRange(stepValue * 4, yAxis, 'Better')

  // Create series
  // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
  let series0 = chart.series.push(
    am5xy.LineSeries.new(root, {
      // calculateAggregates: true,
      xAxis: xAxis,
      yAxis: yAxis,
      valueXField: 'date',
      valueYField: 'value',
      stroke: am5.color(0xffffff),
      fill: am5.color(0xffffff),
      tooltip: am5.Tooltip.new(root, {
        labelText: '{valueX}: [bold]{value}',
      }),
    })
  )

  // Add bullet
  // https://www.amcharts.com/docs/v5/charts/xy-chart/series/#Bullets
  series0.bullets.push(function () {
    const graphics = am5.Circle.new(root, {
      radius: 5,
    })
    graphics.adapters.add('fill', function (fill, target) {
      const value = (target.dataItem?.dataContext as {value: number}).value
      return value > 0 ? am5.color('#18b918') : am5.color('#ff8e2d')
    })
    return am5.Bullet.new(root, {
      sprite: graphics,
    })
  })

  series0.strokes.template.set('strokeOpacity', 0)

  series0.data.processor = am5.DataProcessor.new(root, {
    dateFields: ['date'],
    dateFormat: 'yyyy.MM.dd HH:mm:ss',
  })

  // Add cursor
  // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
  let cursor = chart.set(
    'cursor',
    am5xy.XYCursor.new(root, {
      behavior: 'none',
    })
  )
  cursor.lineY.set('visible', false)

  // Add scrollbars
  // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
  chart.set(
    'scrollbarX',
    am5.Scrollbar.new(root, {
      orientation: 'horizontal',
    })
  )

  series0.data.setAll(newData)

  const range0DataItem = yAxis.makeDataItem({
    value: -stepValue * 4,
    endValue: -stepValue * 2,
  })
  yAxis.createAxisRange(range0DataItem)
  range0DataItem.get('axisFill')?.setAll({
    visible: true,
    fillOpacity: 1,
    fill: am5.Color.fromString('#969696'),
  })

  let range1DataItem = yAxis.makeDataItem({
    value: -stepValue * 2,
    endValue: 0,
  })
  yAxis.createAxisRange(range1DataItem)
  range1DataItem.get('axisFill')?.setAll({
    visible: true,
    fillOpacity: 1,
    fill: am5.Color.fromString('#afafaf'),
  })

  let range2DataItem = yAxis.makeDataItem({
    value: 0,
    endValue: stepValue * 2,
  })
  yAxis.createAxisRange(range2DataItem)
  range2DataItem.get('axisFill')?.setAll({
    visible: true,
    fillOpacity: 1,
    fill: am5.Color.fromString('#d2d2d2'),
  })

  let range3DataItem = yAxis.makeDataItem({
    value: stepValue * 2,
    endValue: stepValue * 4,
  })
  yAxis.createAxisRange(range3DataItem)
  range3DataItem.get('axisFill')?.setAll({
    visible: true,
    fillOpacity: 1,
    fill: am5.Color.fromString('#e3e3e3'),
  })

  if (!newData.length) return showNoDataDisplay(chartId, root)

  // Make stuff animate on load
  // https://www.amcharts.com/docs/v5/concepts/animations/
  series0.appear(1000)
  chart.appear(1000, 100)

  return root
}
