import {formatDuration, intervalToDuration} from 'date-fns'
import LoadingWrapper from '../../../../components/LoadingWrapper'
import {AssetState} from '../../../../core/_models'
import {
  usePlacement,
  useProductionLineConsumptionByCategory,
} from '../../../../core/requests/factory'
import {startTimeFromIso8601Duration} from '../../../../core/time-util'
import {PieGraph} from '../../components/charts/PieGraph'
import {PieData} from '../../core/_chartConfigs/pieConfig'
import {WidgetProps} from './Widget'
import * as am5 from '@amcharts/amcharts5'

interface EnergyLegendProps {
  name: string
  consumption: number
  time: number
}

const EnergyLegend = ({name, consumption, time}: EnergyLegendProps) => {
  return (
    <>
      <strong>{name}</strong>
      <ul>
        <li className={`text-nowrap`}>
          {formatDuration(
            intervalToDuration({
              start: 0,
              end: time * 1000,
            })
          )}
        </li>
        <li className={`text-nowrap`}>{consumption} kWh</li>
      </ul>
    </>
  )
}

const EnergyPiechartWidget = ({widget, height}: WidgetProps) => {
  const {data: placement} = usePlacement(widget.entity)

  const startTime = widget.dynamic_time_range
    ? startTimeFromIso8601Duration(widget.dynamic_time_range)
    : widget.start_time
  const endTime = widget.dynamic_time_range ? undefined : widget.end_time

  const {data, isLoading} = useProductionLineConsumptionByCategory(
    placement?.production_line?._id as string,
    {
      placement_uuids: widget.entities,
      master_placement_uuid: widget.entity,
      operational_intervals: widget.operational_states || ['running', 'standby'],
      operational_interval_mins: widget.operational_state_intervals?.map((v) => v[0]),
      operational_interval_maxs: widget.operational_state_intervals?.map((v) => v[1]),
      start_time: startTime || null,
      end_time: endTime || null,
    }
  )

  const consumptions = data?.consumptions

  const pieData: PieData[] = []
  if (consumptions !== undefined) {
    Object.keys(consumptions).forEach((category) =>
      pieData.push({
        label: category,
        value: consumptions[category].consumption,
        color: StateToColor(category),
      })
    )
  }

  return (
    <div className={`d-flex`}>
      <LoadingWrapper loading={isLoading}>
        <PieGraph id={widget._id} height={height} data={pieData} />
        <div className={`p-5`}>
          {consumptions?.running?.time ? (
            <EnergyLegend
              name='Running'
              consumption={consumptions.running.consumption}
              time={consumptions.running.time}
            />
          ) : null}
          {consumptions?.standby?.time ? (
            <EnergyLegend
              name='StandBy'
              consumption={consumptions.standby.consumption}
              time={consumptions.standby.time}
            />
          ) : null}
          {consumptions?.shutdown?.time ? (
            <EnergyLegend
              name='Shutdown'
              consumption={consumptions.shutdown.consumption}
              time={consumptions.shutdown.time}
            />
          ) : null}
        </div>
      </LoadingWrapper>
    </div>
  )
}

const StateToColor = (state: AssetState) => {
  switch (state) {
    case 'running':
      return am5.color('#228002')
    case 'standby':
      return am5.color('#7d85b9')
    case 'shutdown':
      return am5.color('#ff0202')
  }
}

export default EnergyPiechartWidget
