import React from 'react'
import {useQueryClient} from 'react-query'
import {Link} from 'react-router-dom'
import {KTSVG} from '../../../../_metronic/helpers'
import LoadingWrapper from '../../../components/LoadingWrapper'
import AttachmentList from '../../../components/uploader/AttachmentList'
import WorkOrderStatusBadge from '../../../components/work-orders/WorkOrderStatusBadge'
import WorkOrderCategoryBadge from '../../../components/work-orders/WorkOrderCategoryBadge'
import {entityToString} from '../../../core/name-util'
import {useFactoryEntities} from '../../../core/requests/factory'
import {deleteWorkOrder, useWorkOrder} from '../../../core/requests/maintenance'
import {rfc3339ToString, timeStampToString} from '../../../core/time-util'
import {ConfirmDeletionModal} from '../../overview/core/columns/TableSelectionToolbar'
import {WorkOrderCompleteModal} from '../../schedule/WorkOrderCompleteModal'
import {WorkOrderStartModal} from '../../schedule/WorkOrderStartModal'
import WorkOrderModal from '../modal/WorkOrderModal'

const COLOR_THEMES = ['primary', 'success', 'info', 'warning', 'danger', 'dark']

type Props = {
  workOrderId: string
  modalCallback?: () => void
}

const WorkOrderDetails = ({workOrderId, modalCallback}: Props) => {
  const queryClient = useQueryClient()
  const {data: workOrder, isLoading} = useWorkOrder({workOrderId})
  const [showEditingModal, setShowEditingModal] = React.useState(false)
  const [showDeletingModal, setShowDeletingModal] = React.useState(false)
  const [showStartStopModal, setShowStartStopModal] = React.useState(false)

  const {data: factoryEntities} = useFactoryEntities({
    entityIds: [workOrder?.factory_entity!],
    options: {enabled: !!workOrder?.factory_entity},
  })

  const onDelete = async () => {
    if (workOrder) {
      await deleteWorkOrder(workOrder._id)
      await queryClient.invalidateQueries('workOrders')
    }
    if (modalCallback) {
      modalCallback()
    }
  }

  if (!workOrder?._id || isLoading) {
    return <LoadingWrapper loading={isLoading} />
  }

  return (
    <>
      <div className='modal-header border-0'>
        <div className='symbol-group symbol-hover'>
          {workOrder.assignees &&
            workOrder.assignees.length &&
            workOrder.assignees.map((assignee, index) => (
              <div className='symbol symbol-45px symbol-circle' key={index}>
                <span
                  className={`symbol-label bg-light-${
                    COLOR_THEMES[index % COLOR_THEMES.length]
                  } text-${COLOR_THEMES[index % COLOR_THEMES.length]} 40px`}
                  title={assignee}
                >
                  {assignee.charAt(0).toUpperCase()}
                </span>
              </div>
            ))}
        </div>
        <div>
          {workOrder.status === 'pending' && (
            <div
              className='btn btn-icon btn-sm btn-color-gray-400 btn-active-icon-primary me-2'
              title='Edit Work Order'
              onClick={() => setShowEditingModal(true)}
            >
              <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-2' />
            </div>
          )}

          <div
            className='btn btn-icon btn-sm btn-color-gray-400 btn-active-icon-danger me-2'
            title='Delete Work Order'
            onClick={() => setShowDeletingModal(true)}
          >
            <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-2' />
          </div>
          {modalCallback && (
            <div
              className='btn btn-icon btn-sm btn-color-gray-500 btn-active-icon-primary'
              title='Hide Work Order'
              onClick={modalCallback}
            >
              <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
            </div>
          )}
        </div>
      </div>

      {/* Body */}
      <div className='modal-body pt-0 pb-20 px-lg-17'>
        <div className='row'>
          {/* Work Order Info */}
          <div className='col-6 border-end'>
            <div className='d-flex'>
              {workOrder.priority && (
                <i
                  className='fa-solid fa-flag fs-2 me-5 pt-1'
                  style={{
                    color:
                      workOrder.priority === 'LOW'
                        ? '#D8D8D8'
                        : workOrder.priority === 'MEDIUM'
                        ? '#6FDDFF'
                        : workOrder.priority === 'HIGH'
                        ? '#FFCC00'
                        : '#F50000',
                  }}
                />
              )}

              <div className='mb-9'>
                <div className='d-flex align-items-center mb-2'>
                  <span className='fs-3 fw-bold me-3'>{workOrder.title}</span>
                  <WorkOrderStatusBadge status={workOrder.status} />
                </div>
                {workOrder.category && <WorkOrderCategoryBadge category={workOrder.category} />}
                <div className='fs-6'>{workOrder.description || ''}</div>
              </div>
            </div>

            {/* Start and end times */}
            <div className='d-flex align-items-center mb-2'>
              <KTSVG
                path='/media/icons/duotune/abstract/abs050.svg'
                className='svg-icon-1 svg-icon-success me-5'
              />
              <div className='fs-6'>
                <span className='fw-bold me-2'>Starts</span>
                <span>
                  {rfc3339ToString(
                    workOrder.start_time ? workOrder.start_time : workOrder.planned_start_time
                  )}
                </span>
              </div>
            </div>
            <div className='d-flex align-items-center'>
              <KTSVG
                path='/media/icons/duotune/abstract/abs050.svg'
                className='svg-icon-1 svg-icon-danger me-5'
              />
              <div className='fs-6'>
                <span className='fw-bold me-2'>Ends</span>
                <span>
                  {workOrder.end_time
                    ? rfc3339ToString(workOrder.end_time)
                    : timeStampToString(
                        new Date(workOrder.planned_start_time).getTime() / 1000 +
                          workOrder.expected_duration * 60
                      )}
                </span>
              </div>
            </div>

            {/* Location */}
            {workOrder.factory_entity && (
              <div className='d-flex align-items-center mt-9'>
                <KTSVG
                  path='/media/icons/duotune/general/gen018.svg'
                  className='svg-icon-1 svg-icon-muted me-5'
                />
                <div className='fs-6'>
                  <span className='fw-bold me-4'>At</span>
                  {factoryEntities?.items && factoryEntities.items[0] && (
                    <span>{entityToString(factoryEntities.items[0])}</span>
                  )}
                </div>
              </div>
            )}

            {/* Note */}
            {workOrder.note && (
              <div className='d-flex align-items-center mt-9'>
                <KTSVG
                  path='/media/icons/duotune/general/gen055.svg'
                  className='svg-icon-1 svg-icon-muted me-5'
                />
                <div className='fs-6'>
                  <span className='fw-bold me-4'>Note</span>
                  <span>{workOrder.note}</span>
                </div>
              </div>
            )}
          </div>

          {/* Actions */}
          <div className='col-6'>
            <div className='ms-5'>
              <div className='fs-6 fw-bold'>Actions</div>
              <ul className=''></ul>
              {workOrder.actions.map((action, index) => (
                <li className='d-flex align-items-center' key={index}>
                  {action.is_completed ? (
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr085.svg'
                      className='svg-icon-1 svg-icon-success me-5'
                    />
                  ) : (
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr090.svg'
                      className='svg-icon-1 svg-icon-muted me-5'
                    />
                  )}

                  <div className='fs-6'>
                    <span>{action.description}</span>
                  </div>
                </li>
              ))}
            </div>
          </div>
          <div>
            <AttachmentList
              attachments={workOrder.attachments || []}
              actions={[
                {
                  getElement: (attachment, index) => (
                    <Link to={attachment.url || '#'}>
                      <i className={`fa fa-download me-5 link-text-primary cursor-pointer`} />
                    </Link>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </div>

      {/* Footer */}
      {workOrder.status === 'pending' && (
        <div className='modal-footer d-flex justify-content-end'>
          <button
            type='button'
            className='btn btn-primary'
            onClick={() => setShowStartStopModal(true)}
          >
            Start
          </button>
          <WorkOrderStartModal
            show={showStartStopModal}
            handleClose={() => {
              setShowStartStopModal(false)
              if (modalCallback) {
                modalCallback()
              }
            }}
            workOrderId={workOrder._id}
          />
        </div>
      )}
      {workOrder.status === 'in progress' && (
        <div className='modal-footer d-flex justify-content-end'>
          <button
            type='button'
            className='btn btn-lg btn-primary'
            onClick={() => setShowStartStopModal(true)}
          >
            Complete
          </button>
          <WorkOrderCompleteModal
            show={showStartStopModal}
            handleClose={() => {
              setShowStartStopModal(false)
              if (modalCallback) {
                modalCallback()
              }
            }}
            workOrder={workOrder}
          />
        </div>
      )}

      <WorkOrderModal
        show={showEditingModal}
        hide={() => setShowEditingModal(false)}
        edit={workOrder}
        initialValues={{
          attachments: workOrder.attachments,
        }}
      />

      <ConfirmDeletionModal
        show={showDeletingModal}
        hide={() => setShowDeletingModal(false)}
        deleteSelections={onDelete}
      />
    </>
  )
}

export default WorkOrderDetails
