/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'

type Props = {
  str: string
  href?: string
  icon?: string
}

const StringCell: FC<Props> = ({str, href, icon}) => (
  <div className='d-flex align-items-center'>
    {icon && <i className={`fa ${icon} me-2 fs-1`} />}
    <a href={href} className='text-gray-800 text-hover-primary mb-1'>
      {str}
    </a>
  </div>
)

export {StringCell}
