import axios from 'axios'
import {UseQueryOptions, useQuery} from 'react-query'
import usePaginatedQuery from '../../components/pagination/usePaginatedQuery'
import {
  DeletionConfirmation,
  PaginatedResponse,
  Product,
  Batch,
  BatchStatus,
  BatchTemplate,
  BatchTemplateRates,
  PaginatedQueryProps,
  OrganizationQueryProps,
  BaseModel,
  PaginatedRequestParams,
  OrganizationRequestParams,
  StopCause,
  EventCategoryGroup,
  EventCategory,
  SubEventCategory,
  OeeEventLog,
  OeeMetric,
  MachineProductionStats,
  ICreateBatch,
  MachineOperationalStats,
  Machine,
} from '../_models'
import {ICreateBatchTemplate, IUpdateBatchTemplate} from '../../pages/batch-templates/_models'

import {ICreateProduct, IEditProduct} from '../../pages/planner/CreateProductsModal'
import qs from 'qs'
import {patchObjectDates, patchObjectsDates} from '../request-util'
import {
  UsePaginatedInfiniteQueryOptions,
  usePaginatedInfiniteQuery,
} from '../../components/pagination/usePaginatedInfiniteQuery'

const OEE_API_BASE_URL = process.env.REACT_APP_WA_OEE_URL

// ----- BATCHES ----- //

const BATCHES_URL = `${OEE_API_BASE_URL}/batches`

type UseBatchesProps = PaginatedQueryProps<Batch & BaseModel> & {
  createdFromTemplate?: string
  productionLine?: string
  product?: string
  status?: BatchStatus[]
}

type UseBatchesRequestParams = PaginatedRequestParams & {
  created_from_template?: string
  production_line?: string
  product?: string
  status?: BatchStatus[]
}

export const fetchBatches = (params: UseBatchesRequestParams) =>
  axios.get<PaginatedResponse<Batch & BaseModel>>(BATCHES_URL, {
    params,
    paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat', skipNulls: true}),
  })

export function useBatches(props: UseBatchesProps = {}) {
  const {createdFromTemplate, productionLine, product, status, page, size, options} = props
  const params: UseBatchesRequestParams = {
    created_from_template: createdFromTemplate,
    production_line: productionLine,
    product,
    status,
    page,
    size,
  }
  return useQuery<PaginatedResponse<Batch & BaseModel>, Error>(
    ['batches', ...Object.values(params)],
    async () => {
      const data = (await fetchBatches(params)).data
      if (data?.items) {
        data.items = patchObjectsDates<Batch & BaseModel>(data.items, [
          'created_at',
          'updated_at',
          'start_time',
          'end_time',
          'planned_start_time',
        ])
      }
      return data
    },
    options
  )
}

export async function createBatch(props: ICreateBatch) {
  const {data} = await axios.post<Batch & BaseModel>(BATCHES_URL, props)
  return data
}

type UseBatchProps = {
  batch?: string
  options?: UseQueryOptions<Batch & BaseModel, Error>
}

export function useBatch(props: UseBatchProps = {}) {
  const {batch, options} = props
  return useQuery<Batch & BaseModel, Error>(
    ['batch', batch],
    async () => {
      let {data} = await axios.get<Batch & BaseModel>(`${BATCHES_URL}/${batch}`)
      if (data) {
        data = patchObjectDates<Batch & BaseModel>(data, [
          'created_at',
          'updated_at',
          'start_time',
          'end_time',
          'planned_start_time',
        ])
      }
      return data
    },
    {enabled: !!batch, ...options}
  )
}

export async function updateBatch(batch: string, data: Partial<Batch>) {
  const {data: updatedData} = await axios.put<Batch & BaseModel>(`${BATCHES_URL}/${batch}`, data)
  return updatedData
}

export async function deleteBatch(batch: string) {
  const {data} = await axios.delete<DeletionConfirmation>(`${BATCHES_URL}/${batch}`)
  return data
}

export const uploadBatch = async (file: File) => {
  const formData = new FormData()
  formData.append('file', file)
  return await axios.post(`${BATCHES_URL}/upload`, formData)
}

export type InitateBatchProps = {
  batchId: string
}

export async function initiateBatch({batchId}: InitateBatchProps) {
  const {data} = await axios.put<Batch & BaseModel>(`${BATCHES_URL}/${batchId}/initiate`)
  return data
}

export type CompleteBatchProps = {
  batchId: string
}

export async function completeBatch({batchId}: CompleteBatchProps) {
  const {data} = await axios.put<Batch & BaseModel>(`${BATCHES_URL}/${batchId}/complete`)
  return data
}

// ----- BATCH TEMPLATES ----- //

const BATCH_TEMPLATES_URL = `${OEE_API_BASE_URL}/batch-templates`

type UseBatchTemplatesProps = PaginatedQueryProps<BatchTemplate & BaseModel> & {
  createdBy?: string
  updatedBy?: string
} & OrganizationQueryProps

type UseBatchTemplatesRequestParams = PaginatedRequestParams & {
  created_by?: string
  updated_by?: string
} & OrganizationRequestParams

export function useBatchTemplates(props: UseBatchTemplatesProps = {}) {
  const {organization, createdBy, updatedBy, page, size, options} = props
  const params: UseBatchTemplatesRequestParams = {
    organization,
    created_by: createdBy,
    updated_by: updatedBy,
    page,
    size,
  }
  return useQuery<PaginatedResponse<BatchTemplate & BaseModel>, Error>(
    ['batchTemplates', ...Object.values(params)],
    async () => {
      let data = (
        await axios.get<PaginatedResponse<BatchTemplate & BaseModel>>(BATCH_TEMPLATES_URL, {params})
      ).data
      if (data?.items) {
        data.items = patchObjectsDates<BatchTemplate & BaseModel>(data.items, [
          'created_at',
          'updated_at',
        ])
      }
      return data
    },
    options
  )
}

export async function createBatchTemplate(props: ICreateBatchTemplate) {
  const {data} = await axios.post<BatchTemplate & BaseModel>(BATCH_TEMPLATES_URL, props)
  return data
}

export async function updateBatchTemplate(template: string, data: IUpdateBatchTemplate) {
  const {data: updatedData} = await axios.put<BatchTemplate & BaseModel>(
    `${BATCH_TEMPLATES_URL}/${template}`,
    data
  )
  return updatedData
}

export async function deleteBatchTemplate(batchTemplate: string) {
  const {data} = await axios.delete<DeletionConfirmation>(`${BATCH_TEMPLATES_URL}/${batchTemplate}`)
  return data
}

type UseBatchTemplateProps = {
  batchTemplate?: string
  options?: UseQueryOptions<BatchTemplateRates[], Error>
}

export function useBatchTemplateRates(props: UseBatchTemplateProps = {}) {
  const {batchTemplate, options} = props
  return useQuery<BatchTemplateRates[], Error>(
    ['batchTemplateRates', batchTemplate],
    async () => {
      const {data} = await axios.get<BatchTemplateRates[]>(
        `${BATCH_TEMPLATES_URL}/${batchTemplate}/rates`
      )
      return data
    },
    {enabled: !!batchTemplate, ...options}
  )
}

// ----- PRODUCTS ----- //

const PRODUCTS_URL = `${OEE_API_BASE_URL}/products`

type UseProductsProps = PaginatedQueryProps<Product & BaseModel> & {
  productionLine?: string
} & OrganizationQueryProps

type UseProductsRequestParams = PaginatedRequestParams & {
  production_line?: string
} & OrganizationRequestParams

export function useProducts(props: UseProductsProps = {}) {
  const {organization, productionLine, page, size, options} = props
  const params: UseProductsRequestParams = {
    organization,
    production_line: productionLine,
    page,
    size,
  }
  return useQuery<PaginatedResponse<Product & BaseModel>, Error>(
    ['products', ...Object.values(params)],
    async () => {
      let data = (await axios.get<PaginatedResponse<Product & BaseModel>>(PRODUCTS_URL, {params}))
        .data
      if (data?.items) {
        data.items = patchObjectsDates<Product & BaseModel>(data.items, [
          'created_at',
          'updated_at',
        ])
      }
      return data
    },
    options
  )
}

export const useProductsPaginated = (props: UseProductsProps = {}) =>
  usePaginatedQuery<Product & BaseModel, typeof useProducts>({
    useQuery: useProducts,
    props,
  })

export async function createProduct(props: ICreateProduct) {
  const {data} = await axios.post<Product & BaseModel>(PRODUCTS_URL, props)
  return data
}

export async function editProduct(product: string, productData: IEditProduct) {
  const {data} = await axios.put<Product & BaseModel>(`${PRODUCTS_URL}/${product}`, productData)
  return data
}

export async function deleteProduct(product: string) {
  const {data} = await axios.delete<DeletionConfirmation>(`${PRODUCTS_URL}/${product}`)
  return data
}

// ----- STOP CAUSES ----- //

const STOP_CAUSES_URL = `${OEE_API_BASE_URL}/stop-causes`

type UseStopCausesProps = PaginatedQueryProps<StopCause & BaseModel>

type UseStopCausesRequestParams = PaginatedRequestParams

export function useStopCauses(props: UseStopCausesProps = {}) {
  const {page, size, options} = props
  const params: UseStopCausesRequestParams = {
    page,
    size,
  }
  return useQuery<PaginatedResponse<StopCause & BaseModel>, Error>(
    ['stopCauses', ...Object.values(params)],
    async () => {
      let data = (
        await axios.get<PaginatedResponse<StopCause & BaseModel>>(STOP_CAUSES_URL, {params})
      ).data
      if (data?.items) {
        data.items = patchObjectsDates<StopCause & BaseModel>(data.items, [
          'created_at',
          'updated_at',
        ])
      }
      return data
    },
    options
  )
}

export type CreateStopCauseProps = {
  name: string
  subcauses?: string[]
  color: string
}

export async function createStopCause({name, subcauses, color}: CreateStopCauseProps) {
  const {data} = await axios.post<StopCause & BaseModel>(STOP_CAUSES_URL, {name, subcauses, color})
  return data
}

// ----- EVENT CATEGORY GROUPS ----- //

const EVENT_CATEGORY_GROUPS_URL = `${OEE_API_BASE_URL}/event-category-groups`

export type CreateEventCategoryGroupProps = {
  eventCategoryGroup?: string
  template: boolean
  title?: string
  organization?: string
  entities?: string[]
  isActive?: boolean
}

export async function createEventCategoryGroup({
  eventCategoryGroup,
  template,
  title,
  organization,
  entities,
  isActive,
}: CreateEventCategoryGroupProps) {
  const {data} = await axios.post<EventCategoryGroup>(EVENT_CATEGORY_GROUPS_URL, {
    event_category_group: eventCategoryGroup || null,
    template,
    title,
    organization: organization || null,
    entities,
    is_active: isActive,
  })
  return data
}

export type UseEventCategoryGroupsProps = PaginatedQueryProps<EventCategoryGroup> & {
  template?: boolean
  isGlobal?: boolean
  organizations?: string[]
  entities?: string[]
  isActive?: boolean
}

export type UseEventCategoryGroupsRequestParams = PaginatedRequestParams & {
  template?: boolean
  is_global?: boolean
  organizations?: string[]
  entities?: string[]
  is_active?: boolean
}

export function useEventCategoryGroups(props: UseEventCategoryGroupsProps = {}) {
  const {template, isGlobal, organizations, entities, isActive, page, size, options} = props
  const params: UseEventCategoryGroupsRequestParams = {
    template,
    is_global: isGlobal,
    organizations,
    entities,
    is_active: isActive,
    page,
    size,
  }
  return useQuery<PaginatedResponse<EventCategoryGroup>, Error>(
    ['eventCategoryGroups', ...Object.values(params)],
    async () => {
      const {data} = await axios.get<PaginatedResponse<EventCategoryGroup>>(
        EVENT_CATEGORY_GROUPS_URL,
        {
          params,
          paramsSerializer: (params) =>
            qs.stringify(params, {arrayFormat: 'repeat', skipNulls: true}),
        }
      )
      return data
    },
    options
  )
}

export type UpdateEventCategoryGroupProps = {
  id: string
  title?: string
  template?: boolean
  organizations?: string[]
  entities?: string[]
  isActive?: boolean
}

export async function updateEventCategoryGroup({
  id,
  title,
  template,
  organizations,
  entities,
  isActive,
}: UpdateEventCategoryGroupProps) {
  const {data} = await axios.put<EventCategoryGroup>(`${EVENT_CATEGORY_GROUPS_URL}/${id}`, {
    title,
    template,
    organizations,
    entities,
    is_active: isActive,
  })
  return data
}

export type DeleteEventCategoryGroupProps = {
  id: string
  migrateTo?: string
}

export async function deleteEventCategoryGroup({id, migrateTo}: DeleteEventCategoryGroupProps) {
  const params = {migrate_to: migrateTo || null}
  const {data} = await axios.delete<DeletionConfirmation>(`${EVENT_CATEGORY_GROUPS_URL}/${id}`, {
    data: params,
  })
  return data
}

// ----- EVENT CATEGORIES ----- //

const EVENT_CATEGORIES_URL = `${OEE_API_BASE_URL}/event-categories`

export type CreateEventCategoryProps = {
  template: boolean
  organization?: string
  eventCategoryGroups: string[]
  unscheduled: boolean
  operational: boolean
  title: string
  color: string
  icon: string
  subEventCategories: SubEventCategory[]
}

export async function createEventCategory({
  template,
  organization,
  eventCategoryGroups,
  unscheduled,
  operational,
  title,
  color,
  icon,
  subEventCategories,
}: CreateEventCategoryProps) {
  const {data} = await axios.post<EventCategory>(EVENT_CATEGORIES_URL, {
    template,
    organization: organization || null,
    event_category_groups: eventCategoryGroups,
    unscheduled,
    operational,
    title,
    color,
    icon,
    sub_event_categories: subEventCategories,
  })
  return data
}

export type UseEventCategoriesProps = PaginatedQueryProps<EventCategory> & {
  ids?: string[]
  template?: boolean
  organizations?: string[]
  eventCategoryGroups?: string[]
  unscheduled?: boolean
  operational?: boolean
}

export type UseEventCategoriesRequestParams = PaginatedRequestParams & {
  ids?: string[]
  template?: boolean
  organizations?: string[]
  event_category_groups?: string[]
  unscheduled?: boolean
  operational?: boolean
}

export function useEventCategories(props: UseEventCategoriesProps = {}) {
  const {
    ids,
    template,
    organizations,
    eventCategoryGroups,
    unscheduled,
    operational,
    page,
    size,
    options,
  } = props
  const params: UseEventCategoriesRequestParams = {
    ids,
    template,
    organizations,
    event_category_groups: eventCategoryGroups,
    unscheduled,
    operational,
    page,
    size,
  }
  return useQuery<PaginatedResponse<EventCategory>, Error>(
    ['eventCategories', ...Object.values(params)],
    async () => {
      const {data} = await axios.get<PaginatedResponse<EventCategory>>(EVENT_CATEGORIES_URL, {
        params,
        paramsSerializer: (params) =>
          qs.stringify(params, {arrayFormat: 'repeat', skipNulls: true}),
      })
      return data
    },
    options
  )
}

export type UseEventCategoryProps = {
  id?: string
  options?: UseQueryOptions<EventCategory, Error>
}

export function useEventCategory(props: UseEventCategoryProps = {}) {
  const {id, options} = props
  return useQuery<EventCategory, Error>(
    ['eventCategory', id],
    async () => {
      const {data} = await axios.get<EventCategory>(`${EVENT_CATEGORIES_URL}/${id}`)
      return data
    },
    {enabled: !!id, ...options}
  )
}

export type UpdateEventCategoryProps = {
  id: string
  template?: boolean
  organization?: string
  eventCategoryGroups?: string[]
  unscheduled?: boolean
  operational?: boolean
  title?: string
  color?: string
  icon?: string
  subEventCategories?: SubEventCategory[]
}

export async function updateEventCategory({
  id,
  template,
  organization,
  eventCategoryGroups,
  unscheduled,
  operational,
  title,
  color,
  icon,
  subEventCategories,
}: UpdateEventCategoryProps) {
  const {data} = await axios.put<EventCategory>(`${EVENT_CATEGORIES_URL}/${id}`, {
    template,
    organization,
    event_category_groups: eventCategoryGroups,
    unscheduled,
    operational,
    title,
    color,
    icon,
    sub_event_categories: subEventCategories,
  })
  return data
}

export type DeleteEventCategoryProps = {
  id: string
  migrations?: [string, string][]
}

export async function deleteEventCategory({id, migrations}: DeleteEventCategoryProps) {
  const params = {migrations: migrations || null}
  const {data} = await axios.delete<DeletionConfirmation>(`${EVENT_CATEGORIES_URL}/${id}`, {
    data: params,
    paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat', skipNulls: true}),
  })
  return data
}

// ----- EVENT LOGS ----- //

const EVENT_LOGS_URL = `${OEE_API_BASE_URL}/event-logs`

export type CreateEventLogProps = {
  organization: string
  entity: string
  categories: string[]
  logged_by?: string
  note?: string
}

export async function createEventLog({
  organization,
  entity,
  categories,
  logged_by,
  note,
}: CreateEventLogProps) {
  const {data} = await axios.post<OeeEventLog>(EVENT_LOGS_URL, {
    organization,
    entity,
    categories,
    logged_by,
    note,
  })
  return data
}

export type UseEventLogsProps = PaginatedQueryProps<OeeEventLog> & {
  organizations?: string[]
  entities?: string[]
  categories?: string[]
  loggedBy?: string
  startTime?: string
  endTime?: string
  order?: string
}

export type UseEventLogsRequestParams = PaginatedRequestParams & {
  organizations?: string[]
  entities?: string[]
  categories?: string[]
  logged_by?: string
  start_time?: string
  end_time?: string
  order?: string
}

export const fetchEventLogs = async <T = OeeEventLog>(params?: UseEventLogsRequestParams) =>
  (
    await axios.get<PaginatedResponse<T>>(EVENT_LOGS_URL, {
      params,
      paramsSerializer: (params) => qs.stringify(params, {arrayFormat: 'repeat', skipNulls: true}),
    })
  ).data

export function useEventLogs(props: UseEventLogsProps = {}) {
  const {
    organizations,
    entities,
    categories,
    loggedBy,
    startTime,
    endTime,
    order,
    page,
    size,
    options,
  } = props
  const params: UseEventLogsRequestParams = {
    organizations,
    entities,
    categories,
    logged_by: loggedBy,
    start_time: startTime,
    end_time: endTime,
    order,
    page,
    size,
  }
  return useQuery<PaginatedResponse<OeeEventLog>, Error>(
    ['eventLogs', ...Object.values(params)],
    async () => await fetchEventLogs(params),
    options
  )
}

export const useEventLogsPaginated = (
  params: UseEventLogsRequestParams = {},
  options?: UsePaginatedInfiniteQueryOptions
) =>
  usePaginatedInfiniteQuery<OeeEventLog, typeof fetchEventLogs>({
    queryKey: ['eventLogs', ...Object.values(params)],
    fetchFn: fetchEventLogs,
    params,
    enabled: options?.enabled,
  })

export type UpdateEventLogProps = {
  id: string
  time?: string
  organization?: string
  entity?: string
  categories?: string[]
  logged_by?: string
  note?: string
}

export type UpdateEventLogRequestParams = {
  time?: string
  organization?: string
  entity?: string
  categories?: string[]
  logged_by?: string
  note?: string
}

export async function updateEventLog(props: UpdateEventLogProps) {
  const {id, time, organization, entity, categories, logged_by, note} = props
  const params: UpdateEventLogRequestParams = {
    time,
    organization,
    entity,
    categories,
    logged_by,
    note,
  }
  const {data} = await axios.put<OeeEventLog>(`${EVENT_LOGS_URL}/${id}`, params)
  return data
}
// ----- MACHINES ----- //

const MACHINES_URL = `${OEE_API_BASE_URL}/machines`

export type UseMachineOperationalStatsProps = {
  id: string
  startTime?: string
  endTime?: string
  customShortDuration?: number
  options?: UseQueryOptions<MachineOperationalStats, Error>
}

export type UseMachineOperationalStatsRequestParams = {
  start_time?: string
  end_time?: string
  custom_short_duration?: number
}

export function useMachineOperationalStats(props: UseMachineOperationalStatsProps) {
  const {id, startTime, endTime, customShortDuration, options} = props
  const params: UseMachineOperationalStatsRequestParams = {
    start_time: startTime,
    end_time: endTime,
    custom_short_duration: customShortDuration,
  }
  return useQuery<MachineOperationalStats, Error>(
    ['machineOperationalStats', id, ...Object.values(params)],
    async () => {
      const {data} = await axios.get<MachineOperationalStats>(
        `${MACHINES_URL}/${id}/operational-stats`,
        {
          params,
          paramsSerializer: (params) =>
            qs.stringify(params, {arrayFormat: 'repeat', skipNulls: true}),
        }
      )
      return data
    },
    options
  )
}

export type UseMachineProductionStatsProps = {
  id: string
  startTime?: string
  endTime?: string
  options?: UseQueryOptions<MachineProductionStats, Error>
}

export type UseMachineProductionStatsRequestParams = {
  start_time?: string
  end_time?: string
}

export function useMachineProductionStats(props: UseMachineProductionStatsProps) {
  const {id, startTime, endTime, options} = props
  const params: UseMachineProductionStatsRequestParams = {
    start_time: startTime,
    end_time: endTime,
  }
  return useQuery<MachineProductionStats, Error>(
    ['machineProductionStats', id, ...Object.values(params)],
    async () => {
      const {data} = await axios.get<MachineProductionStats>(
        `${MACHINES_URL}/${id}/production-stats`,
        {
          params,
          paramsSerializer: (params) =>
            qs.stringify(params, {arrayFormat: 'repeat', skipNulls: true}),
        }
      )
      return data
    },
    options
  )
}

export type UseMachineOeeProps = {
  id: string
  startTime?: string
  endTime?: string
  customProductRate?: number
  customCategories?: string[]
  customShortDuration?: number
  options?: UseQueryOptions<OeeMetric, Error>
}

export type UseMachineOeeRequestParams = {
  start_time?: string
  end_time?: string
  custom_product_rate?: number
  custom_categories?: string[]
  custom_short_duration?: number
}

export function useMachineOee(UseMachineOeeProps: UseMachineOeeProps) {
  const {
    id,
    startTime,
    endTime,
    customProductRate,
    customCategories,
    customShortDuration,
    options,
  } = UseMachineOeeProps
  const params: UseMachineOeeRequestParams = {
    start_time: startTime,
    end_time: endTime,
    custom_product_rate: customProductRate,
    custom_categories: customCategories,
    custom_short_duration: customShortDuration,
  }
  return useQuery<OeeMetric, Error>(
    ['machineOee', id, ...Object.values(params)],
    async () => {
      const {data} = await axios.get<OeeMetric>(`${MACHINES_URL}/${id}/oee`, {
        params,
        paramsSerializer: (params) =>
          qs.stringify(params, {arrayFormat: 'repeat', skipNulls: true}),
      })
      return data
    },
    options
  )
}
