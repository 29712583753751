import React from 'react'
import StepModalWrapper, {Step} from '../../../components/StepModalWrapper'
import {Attachment, FileAttachment, WorkOrder} from '../../../core/_models'
import {IWorkOrder, defaultCreateWorkOrder} from './_models'
import {
  createWorkOrder,
  createWorkOrderAttachment,
  removeWorkOrderAttachment,
  updateWorkOrder,
} from '../../../core/requests/maintenance'
import Step1, {
  expectedEndTimeIsAtLeastOneMinuteAfterPlannedStartTime,
  plannedStartTimeIsInTheFuture,
} from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import {useProfile} from '../../../core/requests/auth'
import {useQueryClient} from 'react-query'
import {useFactoryEntities} from '../../../core/requests/factory'
import Step4 from './Step4'

type Props = {
  show: boolean
  hide: () => void
  edit?: WorkOrder
  initialValues?: Partial<IWorkOrder>
}

const WorkOrderModal = ({show, hide, edit, initialValues = undefined}: Props) => {
  const queryClient = useQueryClient()
  const [data, setData] = React.useState<IWorkOrder>(
    edit ? workOrderTOIWorkOrder(edit) : defaultCreateWorkOrder
  )

  const {data: profile} = useProfile()

  const {data: factoryEntities} = useFactoryEntities({
    entityIds: edit && edit.factory_entity ? [edit.factory_entity] : [],
    options: {enabled: !!edit?.factory_entity},
  })

  React.useEffect(() => {
    if (!show) return
    if (edit) {
      const newCreateWorkOrder = workOrderTOIWorkOrder(edit)
      setData({
        ...newCreateWorkOrder,
        selected_entity_type:
          !factoryEntities?.items || !factoryEntities.items[0]
            ? undefined
            : factoryEntities.items[0].type,
      })
    } else if (initialValues) {
      setData({...defaultCreateWorkOrder, ...initialValues})
    } else {
      setData({...defaultCreateWorkOrder})
    }
  }, [show, edit, initialValues, factoryEntities])

  const steps: Step<IWorkOrder>[] = [
    {
      title: 'Specifics',
      description: 'Define Work Order Specifics',
      component: Step1,
      validation: (data) =>
        data.title === undefined ||
        data.planned_start_time === undefined ||
        data.expected_end_time === undefined ||
        plannedStartTimeIsInTheFuture(data) !== undefined ||
        expectedEndTimeIsAtLeastOneMinuteAfterPlannedStartTime(data) !== undefined,
    },
    {
      title: 'Entity',
      description: 'Define Work Order Entity',
      component: Step2,
    },
    {
      title: 'Category',
      description: 'Define Work Order Category',
      component: Step3,
    },
    {
      title: 'Attachments',
      description: 'Add or remove attachments',
      component: Step4,
    },
  ]

  const updateAttachments = async (workOrder: WorkOrder, attachments?: FileAttachment[]) => {
    await Promise.all(
      attachments?.map(async (attachment) => {
        if (attachment.file) {
          try {
            await createWorkOrderAttachment({
              workOrderId: workOrder._id,
              file: attachment.file,
            })
          } catch (e) {
            console.warn(e)
          }
        }
      }) || []
    )

    const attachmentsToDelete: Attachment[] = []
    initialValues?.attachments?.forEach((attachment) => {
      if (!attachments?.includes(attachment) && attachment.name) {
        attachmentsToDelete.push(attachment)
      }
    })

    await Promise.all(
      attachmentsToDelete.map(async (attachment) => {
        await removeWorkOrderAttachment({
          workOrderId: workOrder._id,
          name: attachment.name,
        })
      }) || []
    )
    await queryClient.invalidateQueries(['workOrder', workOrder._id])
  }

  const submitCreate = async (data: IWorkOrder) => {
    // data.organization = profile!.organization!._id

    // get number of minutes between planned start time and expected end time
    const plannedStartTime = new Date(data.planned_start_time!)
    const expectedEndTime = new Date(data.expected_end_time!)
    const minutes = (expectedEndTime.getTime() - plannedStartTime.getTime()) / 1000 / 60
    data.expected_duration = minutes
    data.planned_start_time = data.planned_start_time! / 1000

    let newWorkOrder = await createWorkOrder(data)
    await updateAttachments(newWorkOrder, data.attachments)

    await queryClient.invalidateQueries('workOrders')

    return newWorkOrder
  }

  const submitEdit = async (data: IWorkOrder) => {
    // get number of minutes between planned start time and expected end time
    const plannedStartTime = new Date(data.planned_start_time!)
    const expectedEndTime = new Date(data.expected_end_time!)
    const minutes = (expectedEndTime.getTime() - plannedStartTime.getTime()) / 1000 / 60
    data.expected_duration = minutes
    data.planned_start_time = data.planned_start_time! / 1000

    const updateData = {...data}
    delete updateData.attachments

    const newWorkOrder = await updateWorkOrder({
      workOrderId: edit!._id,
      data: updateData,
    })
    await updateAttachments(newWorkOrder, data.attachments)

    await queryClient.invalidateQueries('workOrders')
    return newWorkOrder
  }

  return (
    <StepModalWrapper<IWorkOrder, WorkOrder>
      id='hs_create_work_order_modal'
      title={edit ? 'Edit Work Order' : 'Create Work Order'}
      show={show}
      close={hide}
      steps={steps}
      data={data}
      defaultData={defaultCreateWorkOrder}
      setData={setData}
      submitData={edit ? submitEdit : submitCreate}
      loading={
        profile === undefined ||
        (edit !== undefined && !!edit.factory_entity && factoryEntities === undefined)
      }
    />
  )
}

function workOrderTOIWorkOrder(workOrder: WorkOrder): IWorkOrder {
  return {
    // organization: workOrder.organization,
    planned_start_time: new Date(workOrder.planned_start_time).getTime(),
    expected_duration: workOrder.expected_duration,
    expected_end_time: new Date(
      new Date(workOrder.planned_start_time).getTime() + workOrder.expected_duration * 1000 * 60
    ).getTime(),
    title: workOrder.title,
    factory_entity: workOrder.factory_entity,
    // selected_entity_type: xxx
    actions: workOrder.actions.map((action) => action.description),
    // work_order_template:
    start_time: workOrder.start_time ? new Date(workOrder.start_time).getTime() : undefined,
    end_time: workOrder.end_time ? new Date(workOrder.end_time).getTime() : undefined,
    assignees: workOrder.assignees,
    priority: workOrder.priority,
    category: workOrder.category,
    sub_category: workOrder.sub_category,
    description: workOrder.description,
    // include_diagnosis
    is_editing: true,
    attachments: workOrder.attachments || [],
  }
}

export default WorkOrderModal
