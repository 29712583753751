import * as am5 from '@amcharts/amcharts5'
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated'
import * as am5xy from '@amcharts/amcharts5/xy'
import {PlacementFFT} from '../../../../core/_models'

export function fftChartConfig(chartId: string, data: PlacementFFT[]) {
  const root = am5.Root.new(chartId)

  root.setThemes([am5themes_Animated.new(root)])

  let chart = root.container.children.push(
    am5xy.XYChart.new(root, {
      panX: true,
      panY: true,
      wheelY: 'zoomXY',
      pinchZoomX: true,
      pinchZoomY: true,
    })
  )

  const cursor = chart.set(
    'cursor',
    am5xy.XYCursor.new(root, {
      behavior: 'none',
    })
  )
  cursor.lineY.set('visible', false)

  const xAxis = chart.xAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererX.new(root, {}),
    })
  )

  const yAxis = chart.yAxes.push(
    am5xy.ValueAxis.new(root, {
      renderer: am5xy.AxisRendererY.new(root, {}),
    })
  )

  if (!data.length) {
    const modal = am5.Modal.new(root, {
      content: 'The chart has no data',
    })
    modal.open()
  } else {
    data.map((fft) => {
      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          calculateAggregates: true,
          name: 'Series',
          xAxis: xAxis,
          yAxis: yAxis,
          valueXField: 'frequency',
          valueYField: 'amplitude',
          tooltip: am5.Tooltip.new(root, {
            labelText: '{valueY}: [bold]{frequency}',
          }),
          minDistance: 10,
        })
      )
      series.data.setAll(
        fft.map((v) => ({
          frequency: v[0],
          amplitude: v[1],
        }))
      )
    })
  }
  return root
}
