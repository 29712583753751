import {ClerkProvider} from '@clerk/clerk-react'
import {Suspense} from 'react'
import {Outlet, useNavigate} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'

// Import your publishable key
const PUBLISHABLE_KEY = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY

if (!PUBLISHABLE_KEY) {
  throw new Error('Missing Publishable Key')
}

const App = () => {
  const navigate = useNavigate()
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ClerkProvider
            routerPush={(to) => navigate(to)}
            routerReplace={(to) => navigate(to, {replace: true})}
            publishableKey={PUBLISHABLE_KEY}
          >
            <Outlet />
          </ClerkProvider>
          <MasterInit />
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export {App}
