import {
  usePlacementConsumption,
  useProductionLineConsumption,
  useSectionConsumption,
} from '../../../../core/requests/factory'
import {startTimeFromIso8601Duration} from '../../../../core/time-util'
import useProductProcessedDivider from './useProductProcessedDivider'
import QuantityWidget from './utility-widgets/QuantityWidget'
import {WidgetProps} from './Widget'

const EmissionsWidget = ({widget}: WidgetProps) => {
  const startTime = widget.dynamic_time_range
    ? startTimeFromIso8601Duration(widget.dynamic_time_range)
    : widget.start_time
  const endTime = widget.dynamic_time_range ? undefined : widget.end_time

  const divider = useProductProcessedDivider({widget})

  const {data: productionLineConsumption} = useProductionLineConsumption({
    productionLine: widget.entity,
    startTime,
    endTime,
    options: {enabled: widget.entity_group === 'production_line', refetchOnWindowFocus: false},
  })
  const {data: sectionConsumption} = useSectionConsumption({
    section: widget.entity,
    startTime,
    endTime,
    options: {enabled: widget.entity_group === 'section', refetchOnWindowFocus: false},
  })
  const {data: placementConsumption} = usePlacementConsumption({
    placement: widget.entity,
    startTime,
    endTime,
    options: {enabled: widget.entity_group === 'placement', refetchOnWindowFocus: false},
  })

  let emissions
  switch (widget.entity_group) {
    case 'production_line':
      emissions = productionLineConsumption?.carbon_dioxide_emissions
      break
    case 'section':
      emissions = sectionConsumption?.carbon_dioxide_emissions
      break
    case 'placement':
      emissions = placementConsumption?.carbon_dioxide_emissions
      break
    default:
      break
  }

  return (
    <QuantityWidget
      value={
        emissions !== undefined && divider ? `${(emissions / divider).toFixed(2)} kg` : undefined
      }
      unit='CO2e'
    />
  )
}

export default EmissionsWidget
