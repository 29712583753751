import {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {useParams} from 'react-router-dom'
import {useLookback} from '../../../_metronic/partials/layout/lookback/LookbackProvider'
import {lookbackValueToTime} from '../../core/time-util'
import {
  useProductionLine,
  useProductionLineConsumption,
  useProductionLineStandbyStats,
} from '../../core/requests/factory'
import {CardWrapper} from './components/CardWrapper'
import {PowerConsumptionGraph} from './components/charts/PowerConsumptionGraph'
import {PowerFactorGraph} from './components/charts/PowerFactorGraph'
import {QuantityCard} from './components/QuantityCard'

const PowerMonitoringDashboardPage: FC = () => {
  const {id} = useParams()
  const {values: lookback} = useLookback()

  const [lookbackStartTime, setLookbackStartTime] = useState<string | undefined>(undefined)

  const getOptions = () => ({
    productionLine: id,
    startTime: lookbackStartTime,
  })

  const {data: consumption} = useProductionLineConsumption(getOptions())
  const {data: standbyStats} = useProductionLineStandbyStats(getOptions())

  useEffect(() => {
    setLookbackStartTime(lookbackValueToTime(lookback.productionLine))
  }, [lookback])

  return (
    <>
      {/* 4 top data boxes */}
      <div className='row g-5 g-xl-10 mb-0'>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <QuantityCard title='Energy Consumption' value={consumption?.energy} unit='kWh' />
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <QuantityCard
            title='Standby Energy Consumption'
            value={standbyStats?.energy}
            unit='kWh'
          />
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <QuantityCard
            title='Potential Savings'
            value={standbyStats?.potential_savings.value}
            unit={standbyStats?.potential_savings.currency}
          />
        </div>
        <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-3 mb-md-5 mb-xl-10'>
          <QuantityCard
            title='Estimated Emissions'
            value={
              consumption?.carbon_dioxide_emissions !== undefined
                ? `${consumption.carbon_dioxide_emissions} kg`
                : '-'
            }
            unit='CO2e'
          />
        </div>
      </div>

      {/* Power Consumption Overview graph */}
      <div className='row'>
        <CardWrapper title='Power Consumption'>
          <PowerConsumptionGraph
            entityId={id!}
            entityGroup='production_line'
            height='300px'
            startTime={lookbackStartTime}
          />
        </CardWrapper>
      </div>

      {/* Power Consumption Overview graph */}
      {/* <div className='row'>
        <CardWrapper title='Power Factor'>
          <PowerFactorGraph productionLineId={id!} height='300px' startTime={lookbackStartTime} />
        </CardWrapper>
      </div> */}
    </>
  )
}

const PowerMonitoringDashboardWrapper: FC = () => {
  let {id} = useParams()
  const {data: productionLine} = useProductionLine(id)

  function breadcrumbs(): Array<PageLink> {
    if (!productionLine) return []

    return [
      {
        title: productionLine.area.name,
        path: '',
        isSeparator: false,
        isActive: false,
      },
      {
        title: '',
        path: '',
        isSeparator: true,
        isActive: false,
      },
    ]
  }

  return (
    <>
      <PageTitle breadcrumbs={breadcrumbs()}>{productionLine?.name}</PageTitle>
      <PowerMonitoringDashboardPage />
    </>
  )
}

export {PowerMonitoringDashboardWrapper}
