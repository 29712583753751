import {
  usePlacementStandbyStats,
  useProductionLineStandbyStats,
  useSectionStandbyStats,
} from '../../../../core/requests/factory'
import {startTimeFromIso8601Duration} from '../../../../core/time-util'
import QuantityWidget from './utility-widgets/QuantityWidget'
import {WidgetProps} from './Widget'

const PotentialSavingsWidget = ({widget}: WidgetProps) => {
  const startTime = widget.dynamic_time_range
    ? startTimeFromIso8601Duration(widget.dynamic_time_range)
    : widget.start_time
  const endTime = widget.dynamic_time_range ? undefined : widget.end_time

  const {data: productionLinestandbyStats} = useProductionLineStandbyStats({
    productionLine: widget.entity,
    startTime,
    endTime,
    options: {enabled: widget.entity_group === 'production_line', refetchOnWindowFocus: false},
  })
  const {data: sectionStandbyStats} = useSectionStandbyStats({
    section: widget.entity,
    startTime,
    endTime,
    options: {enabled: widget.entity_group === 'section', refetchOnWindowFocus: false},
  })

  const {data: placementStandbyStats} = usePlacementStandbyStats({
    placement: widget.entity,
    startTime,
    endTime,
    options: {enabled: widget.entity_group === 'placement', refetchOnWindowFocus: false},
  })

  let potentialSavings
  switch (widget.entity_group) {
    case 'production_line':
      potentialSavings = productionLinestandbyStats?.potential_savings
      break
    case 'section':
      potentialSavings = sectionStandbyStats?.potential_savings
      break
    case 'placement':
      potentialSavings = placementStandbyStats?.potential_savings
      break
    default:
      break
  }

  return <QuantityWidget value={potentialSavings?.value} unit={potentialSavings?.currency} />
}

export default PotentialSavingsWidget
