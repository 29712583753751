import {AuthTokenModel} from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuthToken = (): AuthTokenModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const authToken: AuthTokenModel = JSON.parse(lsValue) as AuthTokenModel
    if (authToken) {
      // You can easily check auth_token expiration also
      return authToken
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuthToken = (authToken: AuthTokenModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(authToken)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuthToken = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

export const setupAxios = async (axios: any) => {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    async (config: {headers: {Authorization: string}}) => {
      try {
        // @ts-ignore
        const token = await Clerk.session.getToken()

        if (token) {
          config.headers.Authorization = `Bearer ${token}`
        }
      } catch (e) {
        console.log(e)
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export {getAuthToken, setAuthToken, removeAuthToken, AUTH_LOCAL_STORAGE_KEY}
