import React from 'react'
import {useMemo, useState} from 'react'
import {KTCard} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {
  deleteMaintenancePlan,
  useMaintenancePlans,
  useWorkOrderTemplates,
  useWorkOrderTemplatesNextOccurrences,
} from '../../core/requests/maintenance'
import {SelectedRowsProvider, useSelectedRows} from '../../providers/SelectedRowsProvider'
import {ListSearchComponent} from '../overview/core/TableSearchComponent'
import {TableBody} from '../overview/core/columns/TableBody'
import {TableSelectionToolbar} from '../overview/core/columns/TableSelectionToolbar'
import {TableToolbar} from '../overview/core/columns/TableToolbar'
import {Columns} from './Columns'
import CreatePlanModal from './modal/CreatePlanModal'
import LoadingWrapper from '../../components/LoadingWrapper'
import ViewWorkOrderTemplateModal from './ViewWorkOrderTemplateModal'
import {WorkOrderTemplate} from '../../core/_models'
import {rfc3339ToString} from '../../core/time-util'

const PlansWrapper = () => {
  const {selected, expanded} = useSelectedRows()
  const {data: maintenancePlans} = useMaintenancePlans()
  const columns = useMemo(() => Columns, [])
  const [showCreatePlanModal, setShowCreatePlanModal] = useState(false)

  return (
    <>
      <KTCard>
        {/* Header */}
        <div className='card-header border-0 pt-6'>
          <ListSearchComponent />
          <div className='card-toolbar'>
            {/* Standard toolbar */}
            {selected.length === 0 && (
              <TableToolbar
                buttonText='Plan'
                onButtonClick={() => setShowCreatePlanModal(true)}
                // buttonIcon='/media/icons/duotune/communication/com002.svg'
              />
            )}

            {/* Selection toolbar */}
            {selected.length > 0 && (
              <TableSelectionToolbar onDelete={deleteMaintenancePlan} queryKey='maintenancePlans' />
            )}
          </div>
        </div>

        {/* Body */}
        <TableBody
          tableId='hs_maintenance_plans_table'
          columns={columns}
          dataList={maintenancePlans?.items}
          loading={maintenancePlans === undefined}
          expandable
          expandedId={expanded}
          expandedIdField='_id'
        >
          <InnerRow />
        </TableBody>
      </KTCard>

      <CreatePlanModal show={showCreatePlanModal} hide={() => setShowCreatePlanModal(false)} />
    </>
  )
}

const InnerRow = () => {
  const {expanded: expandedPlan} = useSelectedRows()
  const {data: workOrderTemplates} = useWorkOrderTemplates({
    maintenancePlans: [expandedPlan!],
    options: {enabled: !!expandedPlan},
  })

  const nextOccurrences = useWorkOrderTemplatesNextOccurrences(
    workOrderTemplates?.items?.map((w) => w._id) || [],
    {options: {enabled: !!workOrderTemplates}}
  ).map((nextOccurrence) => nextOccurrence.data)

  const [selectedWorkOrderTemplate, setSelectedWorkOrderTemplate] = useState<WorkOrderTemplate>()

  React.useEffect(() => {
    setSelectedWorkOrderTemplate(undefined)
  }, [workOrderTemplates])

  return (
    <>
      <LoadingWrapper loading={workOrderTemplates === undefined}>
        <div className='row mb-2 p-2'>
          {workOrderTemplates?.items?.map((workOrderTemplate, index) => (
            <div className='col-4' key={index}>
              <label
                className='btn btn-outline btn-outline-dashed btn-active-light-primary w-100 p-4 mb-4'
                onClick={() => setSelectedWorkOrderTemplate(workOrderTemplate)}
              >
                <span className='flex fw-bold fs-3'>
                  <div className='d-flex align-items-center justify-content-center'>
                    <div>{workOrderTemplate.title}</div>
                    {workOrderTemplate.priority && (
                      <i
                        className={`fa-solid fs-2 ms-3 fa-flag`}
                        style={{
                          color:
                            workOrderTemplate.priority === 'LOW'
                              ? '#D8D8D8'
                              : workOrderTemplate.priority === 'MEDIUM'
                              ? '#6FDDFF'
                              : workOrderTemplate.priority === 'HIGH'
                              ? '#FFCC00'
                              : '#F50000',
                        }}
                      />
                    )}
                  </div>
                </span>

                {nextOccurrences[index]?.time ? (
                  <span>{`Next Occurrence: ${rfc3339ToString(nextOccurrences[index]!.time)}`}</span>
                ) : (
                  <span>{`Next Occurrence: No pending work orders`}</span>
                )}
              </label>
            </div>
          ))}
        </div>
      </LoadingWrapper>

      {selectedWorkOrderTemplate && (
        <ViewWorkOrderTemplateModal
          show={!!selectedWorkOrderTemplate}
          hide={() => setSelectedWorkOrderTemplate(undefined)}
          workOrderTemplate={selectedWorkOrderTemplate}
        />
      )}
    </>
  )
}

const PlansPage = () => {
  const {data: maintenancePlans} = useMaintenancePlans()

  return (
    <SelectedRowsProvider data={maintenancePlans?.items || []} idField='_id'>
      <PageTitle>Plans</PageTitle>
      <PlansWrapper />
    </SelectedRowsProvider>
  )
}

export default PlansPage
