import {
  usePlacementConsumption,
  useProductionLineConsumption,
  useSectionConsumption,
} from '../../../../core/requests/factory'
import {startTimeFromIso8601Duration} from '../../../../core/time-util'
import useProductProcessedDivider from './useProductProcessedDivider'
import QuantityWidget from './utility-widgets/QuantityWidget'
import {WidgetProps} from './Widget'

const EnergyConsumptionWidget = ({widget}: WidgetProps) => {
  const startTime = widget.dynamic_time_range
    ? startTimeFromIso8601Duration(widget.dynamic_time_range)
    : widget.start_time
  const endTime = widget.dynamic_time_range ? undefined : widget.end_time

  const divider = useProductProcessedDivider({widget})

  const {data: productionLineConsumption} = useProductionLineConsumption({
    productionLine: widget.entity,
    startTime,
    endTime,
    options: {enabled: widget.entity_group === 'production_line', refetchOnWindowFocus: false},
  })
  const {data: sectionConsumption} = useSectionConsumption({
    section: widget.entity,
    startTime,
    endTime,
    options: {enabled: widget.entity_group === 'section', refetchOnWindowFocus: false},
  })
  const {data: placementConsumption} = usePlacementConsumption({
    placement: widget.entity,
    startTime,
    endTime,
    options: {enabled: widget.entity_group === 'placement', refetchOnWindowFocus: false},
  })

  let energy
  switch (widget.entity_group) {
    case 'production_line':
      energy = productionLineConsumption?.energy
      break
    case 'section':
      energy = sectionConsumption?.energy
      break
    case 'placement':
      energy = placementConsumption?.energy
      break
    default:
      break
  }

  return (
    <QuantityWidget value={!!energy && divider ? (energy / divider).toFixed(2) : 0} unit='kWh' />
  )
}

export default EnergyConsumptionWidget
