import {useQueryClient} from 'react-query'
import {ICreateMaintenancePlan, defaultCreateMaintenancePlan} from './_models'
import {useState} from 'react'
import StepModalWrapper, {Step} from '../../../components/StepModalWrapper'
import {MaintenancePlan} from '../../../core/_models'
import {createMaintenancePlan} from '../../../core/requests/maintenance'
import Step1 from './Step1'
import Step2 from './Step2'

type Props = {
  show: boolean
  hide: () => void
}

const CreatePlanModal = ({show, hide}: Props) => {
  const queryClient = useQueryClient()
  const [data, setData] = useState<ICreateMaintenancePlan>(defaultCreateMaintenancePlan)

  const steps: Step<ICreateMaintenancePlan>[] = [
    {
      title: 'Specifics',
      description: 'Define Maintenance Plan Specifics',
      component: Step1,
      validation: (data) => data.name === undefined,
    },
    {
      title: 'Entities',
      description: 'Define Maintenance Plan Entities',
      component: Step2,
      validation: (data) =>
        data.factory_entities === undefined || data.factory_entities.length === 0,
    },
  ]

  const submitCreate = async (data: ICreateMaintenancePlan) => {
    const newMaintenancePlan = await createMaintenancePlan(data)
    queryClient.invalidateQueries('maintenancePlans')
    return newMaintenancePlan
  }

  return (
    <StepModalWrapper<ICreateMaintenancePlan, MaintenancePlan>
      id='hs_create_maintenance_plan_modal'
      objectName='Maintenance Plan'
      show={show}
      close={hide}
      steps={steps}
      data={data}
      defaultData={defaultCreateMaintenancePlan}
      setData={setData}
      submitData={submitCreate}
    />
  )
}

export default CreatePlanModal
