import React from 'react'
import {AxiosError} from 'axios'
import {UseMutationResult, useQueryClient} from 'react-query'
import {QueryKey} from 'react-query/types/core/types'
import LoadingWrapper from '../LoadingWrapper'
import {ReferenceUUID} from '../../core/_models'

type ReferenceDataType = Record<string, any> & ReferenceUUID

type MutateProps<DataType> = Partial<DataType> & ReferenceUUID

interface Props<DataType extends ReferenceDataType> {
  data: DataType
  dataField: Extract<keyof DataType, string>
  useMutation: () => UseMutationResult<DataType, AxiosError, MutateProps<DataType>>
  editable?: boolean
  queryToInvalidate?: QueryKey
}

const EntityInlineTextFieldForm = <DataType extends ReferenceDataType>({
  data,
  dataField,
  useMutation,
  queryToInvalidate,
  editable = true,
}: Props<DataType>) => {
  const textInput = React.useRef<HTMLInputElement>(null)
  const [editMode, setEditMode] = React.useState(false)
  const baseValue = data[dataField]
  const mutation = useMutation()
  const queryClient = useQueryClient()

  const submit = async () => {
    if (textInput.current?.value !== baseValue) {
      try {
        await mutation.mutateAsync({
          _id: data._id,
          [dataField]: textInput.current?.value || baseValue,
        } as MutateProps<DataType>)
        if (queryToInvalidate) {
          await queryClient.invalidateQueries(queryToInvalidate)
        }
      } catch (e) {
        return
      }
    }
    setEditMode(false)
  }

  return editMode ? (
    <LoadingWrapper loading={mutation.isLoading}>
      <input
        ref={textInput}
        type='text'
        defaultValue={baseValue}
        className={`form-control form-control-sm fs-5 text-dark fw-bold`}
        onKeyUp={(e) => {
          if (e.key.toLowerCase() === 'enter') {
            submit()
          }
        }}
      />
      <button className='btn btn-icon btn-active-color-primary' onClick={submit}>
        <i className='fa-solid fa-check fs-3 mx-4' />
      </button>
    </LoadingWrapper>
  ) : (
    <>
      {data[dataField]}
      {editable && (
        <button className='btn btn-icon btn-active-color-primary' onClick={() => setEditMode(true)}>
          <i className='fa-solid fa-pen fs-3 mx-4' />
        </button>
      )}
    </>
  )
}

export default EntityInlineTextFieldForm
