/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, Navigate} from 'react-router-dom'
import {PrivateRoutes} from './PrivateRoutes'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {App} from '../App'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const AppRoutes: FC = () => (
  <Routes>
    <Route element={<App />}>
      <Route path='error/*' element={<ErrorsPage />} />
      <>
        <Route path='/*' element={<PrivateRoutes />} />
        <Route index element={<Navigate to='/factories' />} />
      </>
    </Route>
  </Routes>
)

export {AppRoutes}
